import { Environment, environment } from 'Environment/Environment';
import { isNativeClient } from 'Environment/launchResource/device';
import { INativeBridgeManager } from 'Environment/NativeBridgeManager';

export class OnpremNativeBridgeManager implements INativeBridgeManager {
	private readonly pendingOnlineOperations = new Set<string>();
	private _allowNetworkCalls = true;

	private constructor() {}

	private static instance: OnpremNativeBridgeManager;

	public static getInstance(): INativeBridgeManager {
		if (!OnpremNativeBridgeManager.instance) {
			OnpremNativeBridgeManager.instance = new OnpremNativeBridgeManager();
		}
		return OnpremNativeBridgeManager.instance;
	}

	public supportsNativeOfflineCapability(): boolean {
		return environment?.nativeCapabilities?.enableOffline ?? false;
	}

	public allowCacheUse(): boolean {
		return !isNativeClient() || this.supportsNativeOfflineCapability();
	}

	public networkCallsAllowed(): boolean {
		//If offline not supported, network calls should be always allowed
		if (!this.supportsNativeOfflineCapability()) {
			return true;
		}

		return this._allowNetworkCalls;
	}

	public setAllowNetworkCalls(allowNwCalls: boolean): void {
		this._allowNetworkCalls = allowNwCalls;
	}

	//This function never throws an error. It always returns a boolean
	private async safeGoOnline(): Promise<boolean> {
		try {
			const result = await environment.goOnline();
			return 'success' === result;
		} catch (error) {
			return false;
		}
	}

	public async makeOnlineCall(
		operationName: string,
		operation: () => void,
		cleanup: () => void
	): Promise<boolean> {
		if (this.pendingOnlineOperations.has(operationName)) {
			//Same operation is already in progress. cleanup and return false
			cleanup();
			return false;
		}

		this.pendingOnlineOperations.add(operationName);
		const successFullyWentOnline = await this.safeGoOnline();
		successFullyWentOnline ? operation() : cleanup();
		this.pendingOnlineOperations.delete(operationName);
		return successFullyWentOnline;
	}

	// During bridge creation, we can try ask the nativeClient to goOnline if env?.nativeCapabilities?.enableOffline is false or undefined.
	// means its not explitly set to true
	public canGoOnlineDuringBridgeCreation(env: Environment): boolean {
		return env?.nativeCapabilities?.enableOffline !== true;
	}

	public canGoOnlineFromBridgeAjaxCall(): boolean {
		return !this.supportsNativeOfflineCapability();
	}
}
