import React from 'react';
import { t } from '@citrite/translate';
import { Button, layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { setInLocalStorage } from 'javascript/sf/Storage';

const StyledTextButton = styled(Button.TextPrimary)<{ smallFormFactor: boolean }>`
	margin-left: ${layout.tinySpace};
	${props =>
		props.smallFormFactor &&
		`
		margin: ${layout.mediumSpace} 0;
	`}
`;

const getPlatformChangeText = (isUsingIpad: boolean) =>
	isUsingIpad
		? t('Workspace:mandate_native_app_screen.switch_to_macos')
		: t('Workspace:mandate_native_app_screen.i_am_using_ipad');

interface Props {
	isUsingIpad: boolean;
}

export const IpadPlatformSwitchButton = ({ isUsingIpad }: Props) => {
	const { isSmallFormFactor } = useFormFactor();
	const platformChangeText = getPlatformChangeText(isUsingIpad);

	const handlePlatformChange = () => {
		setInLocalStorage('userIsUsingAnIpad', !isUsingIpad);
		window.location.reload();
	};

	return (
		<StyledTextButton
			onClick={handlePlatformChange}
			smallFormFactor={isSmallFormFactor}
			data-testid="platform-switch-button"
		>
			{platformChangeText}
		</StyledTextButton>
	);
};
