import React, { useCallback, useState } from 'react';
import { t } from '@citrite/translate';
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	fromThemeColors,
	layout,
	palette,
	Theme,
	typography,
} from '@citrite/web-ui-component';
import { ContentTitle } from '@citrite/workspace-ui-platform-react';
import { css, keyframes, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { head, noop } from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import { useFormFactor } from 'App/Header/useFormFactor';
import { settingsRoute } from 'App/Settings';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

const activeClassName = 'active';

const StyledNavLink = styled(NavLink)`
	text-decoration: none;
	padding: ${layout.smallSpace} 0;
	&.${activeClassName} {
		font-weight: ${typography.weights.semiBold};
	}
`;

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
	align-self: end;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
	position: absolute;
`;

const slideIn = keyframes`
	from {
		width: 0%;
	} to {
		width: 70%;
	}
`;

const StyledDiv = styled.div<{
	isSmallFormFactor: boolean;
	theme: Theme;
	isOffWhiteBackground: boolean;
}>`
	display: flex;
	flex-direction: column;
	padding-top: 20px;
	padding-right: ${layout.largeSpace};
	${props =>
		props.isOffWhiteBackground &&
		!props.isSmallFormFactor &&
		`padding-left: ${layout.largeSpace}; background-color: ${palette.white}; margin-right: ${layout.mediumSpace};`}
	width: 20%;
	${props =>
		props.isSmallFormFactor &&
		css`
			position: absolute;
			width: 70%;
			height: 100%;
			z-index: 1;
			background: ${fromThemeColors('primaryAppBackground')(props)};
			animation: ${slideIn} 0.05s forwards;
		`}
`;

export const SettingsSecondaryNavigation = () => {
	const [show, setShow] = useState<boolean>(false);
	const loadableResourcesContext = useLoadableResourceContext();
	const workspaceConfiguration = useConfigurationContext().workspaceConfiguration;
	const userContext = useUserContext();
	const location = useLocation();
	const theme = useTheme();
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);
	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();

	const closeNavigation = useCallback(() => setShow(false), []);
	const openNavigation = useCallback(() => setShow(true), []);

	const availableRoutes = settingsRoute.childRoutes.filter(route =>
		route.isAvailable({
			location,
			userContext,
			workspaceConfiguration,
			resourceContext: loadableResourcesContext.value,
		})
	);

	return show || isLargeFormFactor ? (
		<StyledDiv
			isSmallFormFactor={isSmallFormFactor}
			theme={theme}
			isOffWhiteBackground={isOffWhiteBackground}
		>
			{!isSmallFormFactor && (
				<ContentTitle setMobileMastheadTitle={noop}>
					{t('Workspace:settings')}
				</ContentTitle>
			)}
			{isSmallFormFactor && <StyledArrowLeftIcon onClick={closeNavigation} />}
			{availableRoutes.map(route => {
				const linkProps = route.getNavLinkProps(workspaceConfiguration);
				return (
					linkProps && (
						<StyledNavLink
							to={head(route.paths)}
							key={route.key}
							onClick={closeNavigation}
							title={linkProps.title}
						>
							{linkProps.title}
						</StyledNavLink>
					)
				);
			})}
		</StyledDiv>
	) : (
		<StyledArrowRightIcon onClick={openNavigation} />
	);
};
