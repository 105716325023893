import { post } from '@citrite/http';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { contentTypeJson } from 'App/Activity/Network/ActionsUtil';
import { ResourceType } from 'App/Tile/ResourceDetails';
import { launchResource } from 'Environment/launchResource';
import { canAutoLaunchSingleDesktop } from 'Environment/launchResource/clientManager';
import { isNativeClient } from 'Environment/launchResource/device';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { ListedSession, Resource } from 'Workspace/ResourceProvider/resourceTypes';
import {
	autoReconnectAllowedByAppShortcuts,
	autoReconnectConfigured,
	isWorkspaceControlEnabled,
	userHasAppsInStore,
} from 'Workspace/WorkspaceControl/workspaceControlUtils';

const launchListedSessions = (
	data: ListedSession[],
	workspaceConfiguration: WorkspaceConfiguration,
	resourceContext: ResourceContext
) => {
	data.forEach(({ launchurl, launchstatusurl }) => {
		const resource: Resource = {
			launchurl,
			launchstatusurl,
		};

		launchResource({
			resource,
			resourceContext,
			workspaceConfiguration,
			launchOptions: {
				isAutoLaunch: true,
			},
		});
	});
};

export const reconnectAndLaunchApps = (
	workspaceConfiguration: WorkspaceConfiguration,
	resourceContext: ResourceContext
) => {
	post<ListedSession[]>(
		workspaceConfiguration?.storeProxy.sessionsProxy.listAvailableURL,
		{
			resourceTypes: ResourceType.VirtualApp,
		},
		contentTypeJson
	).then(data => {
		if (Array.isArray(data)) {
			launchListedSessions(data, workspaceConfiguration, resourceContext);
		}
	});
};

export const isAutoSessionReconnectAllowed = (
	workspaceConfiguration: WorkspaceConfiguration,
	resourceContext: ResourceContext
) => {
	if (isNativeClient()) {
		return false;
	}

	// Don't bother asking for the user's reconnectable sessions if the user just has
	// desktops (since we never reconnect desktops anyway).
	// This prevents any unwanted side effects from occurring to the user's desktops (the
	// server farm disconnects sessions when asked to list them).
	// Note that if the user has both apps and desktops, we do ask for the list of sessions
	// and so side effects may still occur.
	return (
		autoReconnectConfigured(workspaceConfiguration) &&
		isWorkspaceControlEnabled(workspaceConfiguration, resourceContext) &&
		userHasAppsInStore(resourceContext) &&
		autoReconnectAllowedByAppShortcuts(workspaceConfiguration) &&
		!canAutoLaunchSingleDesktop(workspaceConfiguration, resourceContext.resources)
	);
};
