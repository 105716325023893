import { AnalyticsEvent, Metadata } from 'analytics';
import { ShieldHealthCheckEventType } from 'Workspace/TelemetryEvents/TelemetryEventTypes';

const code = 'WSUI_SHIELD_HEALTH_CHECK';

const preparePayload = (
	type: ShieldHealthCheckEventType,
	metadata?: Metadata
): AnalyticsEvent => ({
	type,
	code,
	...metadata,
});

export const ShieldHealthCheckPayload = {
	createHealthCheckSuccessPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.Success),
	createHealthCheckRetryPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.Retry),
	createHealthCheckUnauthenticatedPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.Unauthenticated),
	createHealthCheckCLSyncSuccessPayload: (
		numberOfFilesDownloadedPerClsyncop: number,
		avgClsyncApiResponseTime: number,
		clsyncTime: number,
		customerId: string
	): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.ClSyncSuccess, {
			numberOfFilesDownloadedPerClsyncop,
			avgClsyncApiResponseTime,
			clsyncTime,
			customerId,
		}),
	createHealthCheckCLSyncFailedPayload: (clsyncFailureReason: string): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.ClSyncFail, {
			clsyncFailureReason,
		}),
	createHealthCheckCLMLaunchStartPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.CLMLaunchStart),
	createHealthCheckCLMLaunchMiniIcaGeneratedPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.CLMLaunchMiniIcaGenerated),
	createHealthCheckCLMLaunchFinalIcaGeneratedPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.CLMLaunchFinalIcaGenerated),
	createHealthCheckCLMLaunchHandleVSRResultPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.CLMLaunchHandleVSRResult),
	createHealthCheckCLMLaunchErrorPayload: (): AnalyticsEvent =>
		preparePayload(ShieldHealthCheckEventType.CLMLaunchError),
};
