import { Environment } from 'Environment/Environment';
import { INativeBridgeManager } from 'Environment/NativeBridgeManager';

export class GenericNativeBridgeManager implements INativeBridgeManager {
	public supportsNativeOfflineCapability(): boolean {
		return true;
	}

	public allowCacheUse(): boolean {
		return true;
	}

	public networkCallsAllowed(): boolean {
		return true;
	}

	public setAllowNetworkCalls(_allowNwCalls: boolean): void {
		//no-Operation.
	}

	public async makeOnlineCall(
		_operationName: string,
		operation: () => void,
		_cleanup: () => void
	): Promise<boolean> {
		operation();
		return true;
	}

	public canGoOnlineDuringBridgeCreation(env: Environment): boolean {
		// For cloud environment, we need to make goOnline call
		// only if the environment is connected to Citrix Cloud
		return env.citrixCloudConnected;
	}

	public canGoOnlineFromBridgeAjaxCall(): boolean {
		return true;
	}
}
