import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { getLaunchMethod } from 'Environment/launchResource/clientManager';
import { launchMethod } from 'Environment/launchResource/constants';
import { isAppShortcutLaunch } from 'Environment/launchResource/launchUtil';
import { ResourceContext } from 'Workspace/ResourceProvider';
import { isDesktop } from 'Workspace/ResourceProvider/resourceTypes';

export const userHasAppsInStore = (resourceContext: ResourceContext) =>
	resourceContext.resources.filter(res => !isDesktop(res)).length > 0;

export const isWorkspaceControlEnabled = (
	workspaceConfiguration: WorkspaceConfiguration,
	resourceContext: ResourceContext
) => {
	const workspaceOptions = workspaceConfiguration?.userInterface?.workspaceControl;
	const launchMethodValue = getLaunchMethod(workspaceConfiguration);

	if (workspaceOptions?.enabled === 'false' || resourceContext.isUnauthenticatedStore) {
		// Workspace control is disabled in config or is an anonymous store as workspace
		// control is disabled for an anonymous store
		return false;
	}
	if (launchMethodValue === launchMethod.html5) {
		return (
			workspaceConfiguration?.pluginAssistant?.html5?.singleTabLaunch !== 'true' &&
			!isAppShortcutLaunch()
		);
	}

	return true;
};

export const autoReconnectConfigured = (workspaceConfiguration: WorkspaceConfiguration) =>
	workspaceConfiguration?.userInterface?.workspaceControl?.autoReconnectAtLogon ===
	'true';

export const autoReconnectAllowedByAppShortcuts = (
	workspaceConfiguration: WorkspaceConfiguration
) =>
	!isAppShortcutLaunch() ||
	workspaceConfiguration?.userInterface?.appShortcuts?.allowSessionReconnect === 'true';
