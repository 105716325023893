import * as React from 'react';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { isSimpleViewDisabledInCloudConsole } from 'App/Navigation/utils';
import { configuredServiceCASReporter } from 'App/Reporting/createConfiguredServiceCasReporter';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { gacsTicketFetcher } from 'GlobalAppConfigService';
import { GlobalAppConfigV2Payload } from 'Workspace/TelemetryEvents/globalAppConfigV2/createGlobalAppConfigCASReporter';

export function useConfiguredServicesReporting() {
	const { workspaceConfiguration } = useConfigurationContext();

	React.useEffect(() => {
		const isHomeTabEnabled =
			workspaceConfiguration?.userInterface?.enableWorkspaceHome === 'true';
		const isGacsV2Enabled = gacsTicketFetcher.isGacsV2Enabled(workspaceConfiguration);
		trackAnalyticsEvent(GlobalAppConfigV2Payload.isGACSV2Enabled(isGacsV2Enabled));

		if (hasUIProperty(workspaceConfiguration, UIProperty.Apps)) {
			trackEvent('appsEnabled');
		}

		if (hasUIProperty(workspaceConfiguration, UIProperty.Desktops)) {
			trackEvent('desktopsEnabled');
		}

		if (hasUIProperty(workspaceConfiguration, UIProperty.Files)) {
			trackEvent('filesEnabled');
		}
		if (isHomeTabEnabled) {
			trackAnalyticsEvent(
				configuredServiceCASReporter.getConfiguredServiceHomeTabEnabledEvent()
			);
		} else {
			trackAnalyticsEvent(
				configuredServiceCASReporter.getConfiguredServiceHomeTabDisabledEvent()
			);
		}
		if (isSimpleViewDisabledInCloudConsole()) {
			trackAnalyticsEvent(
				configuredServiceCASReporter.getConfiguredServiceSimpleViewDisabledEvent()
			);
		} else {
			trackAnalyticsEvent(
				configuredServiceCASReporter.getConfiguredServiceSimpleViewEnabledEvent()
			);
		}
	}, [workspaceConfiguration]);
}
