import * as React from 'react';
import { t } from '@citrite/translate';
import { Button, notifyError } from '@citrite/web-ui-component';
import { browser } from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { getFromSessionStorage } from 'javascript/sf/Storage';

const copyTextToClipboard = (transactionId: string, serverName = '') => {
	const text = serverName
		? `Transaction ID: ${transactionId}, Server Name: ${serverName}`
		: transactionId;
	navigator.clipboard.writeText(text);
};

export function errorWithCopyTransactionIdButton(
	errorText: React.ReactNode,
	transactionId: string
) {
	let serverName: string;
	if (transactionId) {
		serverName = getFromSessionStorage(transactionId);
		console.warn(
			`Resource launch failed. Transaction Id: ${transactionId}${
				serverName ? ` Server Name: ${serverName}` : ''
			}`
		);
	}
	return notifyError(
		<>
			<StyledDiv>{errorText}</StyledDiv>
			{!browser.isInternetExplorer() && transactionId ? (
				<Button.TextSecondary
					onClick={() => copyTextToClipboard(transactionId, serverName)}
				>
					<>
						{serverName
							? t('Workspace:desktop_launch_failures.copy_transactionid_and_servername', {
									serverName,
							  })
							: t('Workspace:desktop_launch_failures.click_to_copy')}
					</>
				</Button.TextSecondary>
			) : undefined}
		</>
	);
}

export function errorWithCopyTransactionIdButtonforHtml5LeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	return errorWithCopyTransactionIdButtonforLeaseLaunch(
		errorText,
		errorCode,
		transactionId
	);
}

export function errorWithCopyTransactionIdButtonforChromeAppLeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	return errorWithCopyTransactionIdButtonforLeaseLaunch(
		errorText,
		errorCode,
		transactionId
	);
}

function errorWithCopyTransactionIdButtonforLeaseLaunch(
	errorText: React.ReactNode,
	errorCode: string,
	transactionId: string
) {
	let serverName: string;
	if (transactionId) {
		serverName = getFromSessionStorage(transactionId);
		console.warn(
			`Resource lease launch failed. Transaction Id: ${transactionId}${
				serverName ? ` Server Name: ${serverName}` : ''
			}`
		);
	}
	return notifyError(
		<>
			<StyledDivforLeaseError>
				{t('Workspace:lease_launch_errormsg') + '. ' + errorText}
			</StyledDivforLeaseError>
			<StyledDiv>{t('Workspace:lease_launch_errorcode', { code: errorCode })}</StyledDiv>
			{!browser.isInternetExplorer() && transactionId ? (
				<Button.TextSecondary
					onClick={() => copyTextToClipboard(transactionId, serverName)}
				>
					<>
						{serverName
							? t('Workspace:desktop_launch_failures.copy_transactionid_and_servername', {
									serverName,
							  })
							: t('Workspace:desktop_launch_failures.click_to_copy')}
					</>
				</Button.TextSecondary>
			) : undefined}
			<StyledDiv>{t('Workspace:lease_launch_helpdesk')}</StyledDiv>
		</>
	);
}

const StyledDivforLeaseError = styled.div`
	padding: 5px 5px;
	font-weight: bold;
	width: 300px;
`;

const StyledDiv = styled.div`
	padding: 0px 5px;
`;
