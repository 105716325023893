import React from 'react';
import { t } from '@citrite/translate';
import { layout } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { platformSpecificClientDisplayNames } from 'Components/Detection/nativeClientName';
import { getMobileAppLogo } from 'Components/MandateNativeAppSupport/utils';
import { platform } from 'Environment/launchResource/constants';
import { isMobileOrTabletDevice } from 'Environment/launchResource/device';

const StyledSpan = styled.span<{ smallFormFactor: boolean }>`
	margin-bottom: ${layout.mediumSpace};
	align-items: center;
	display: block;
	${props =>
		props.smallFormFactor &&
		`
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		margin: ${layout.smallSpace} 0;
	`}
`;

const StyledAnchor = styled.a<{ smallFormFactor: boolean }>`
	margin-left: ${layout.tinySpace};
	color: #05758a;
	${props =>
		props.smallFormFactor &&
		`
		margin: ${layout.mediumSpace} 0;
	`}
`;

const getDownloadMessage = (currentPlatform: platform) => {
	switch (currentPlatform) {
		case platform.windows:
		case platform.mac:
			return t('Workspace:mandate_native_app_screen.download_citrix_for_platform', {
				platform: platformSpecificClientDisplayNames.find(
					device => device.platform === currentPlatform
				)?.name,
			});
	}

	return t('Workspace:mandate_native_app_screen.download_citrix');
};

interface Props {
	href: string;
	currentPlatform: platform;
	displayDescription?: boolean;
	onClick?(): void;
}

export const CustomDownloadAppButton = ({
	href,
	currentPlatform,
	onClick = () => {},
	displayDescription = true,
}: Props) => {
	const { isSmallFormFactor } = useFormFactor();

	const downloadLinkDescription = isMobileOrTabletDevice()
		? getMobileAppLogo()
		: getDownloadMessage(currentPlatform);

	return (
		<StyledSpan smallFormFactor={isSmallFormFactor}>
			{displayDescription && t('Workspace:mandate_native_app_screen.dont_have_app')}
			<StyledAnchor
				href={href}
				smallFormFactor={isSmallFormFactor}
				onClick={onClick}
				target="_blank"
				data-testid="download-citrix-href"
			>
				{downloadLinkDescription}
			</StyledAnchor>
		</StyledSpan>
	);
};
