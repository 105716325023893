import { GacsTicketFetcherCloud } from 'GlobalAppConfigService/gacsTicketFetcher';
import { GacsTicketFetcherOnprem } from 'GlobalAppConfigService/gacsTicketFetcherOnprem';

// allow-unused-export: needed for unit tests
export { GacsTicketFetcherCloud, GacsTicketFetcherOnprem };
export { GlobalAppConfigResponse } from 'GlobalAppConfigService/types';

export const gacsTicketFetcher = IS_ON_PREM
	? GacsTicketFetcherOnprem.getInstance()
	: GacsTicketFetcherCloud.getInstance();
