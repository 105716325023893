import * as React from 'react';
import {
	PlatformDependencies,
	UserAccountData,
	UserPreferences,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment } from 'Environment';
import { addNativeLogoutCallback, setUserNameFunction } from 'Environment/callbacks';
import { InternalPlatformDependencies } from 'IntegrationDependencies/InternalDependencies';
import { logoutRoute } from 'Logout';
import { container } from 'Workspace/DependencyManagement';
import {
	Context,
	Loadable,
	UserBranding,
	UserContext,
	UserDetailsLoader,
} from './Context';

interface State {
	userDetails: UserAccountData;
	userPreferences: UserPreferences;
	userBranding: Loadable<UserBranding>;
	isValidatedReturnUser: boolean;
	isIWSEnabled: boolean;
	isDaasVisionEnabled: boolean;
	isActivityManagerEnabled: boolean;
	error: Error;
	userDetailsLoader: UserDetailsLoader;
}

interface Props {
	history: History;
	workspaceConfiguration: WorkspaceConfiguration;
}

const initialState: State = {
	userBranding: { loading: true, value: null },
	isValidatedReturnUser: null,
	isIWSEnabled: null,
	isDaasVisionEnabled: true,
	isActivityManagerEnabled: null,
	userDetails: null,
	userPreferences: null,
	error: null,
	userDetailsLoader: null,
};

class _UserProvider extends React.Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			...initialState,
			isActivityManagerEnabled:
				!!this.props.workspaceConfiguration?.storeProxy?.sessionControlProxy,
		};
		container.registerSingleton(InternalPlatformDependencies.UserDetails, {
			get: () => [this.state.userDetails, this.state.userDetailsLoader],
		});
		container.registerSingleton(PlatformDependencies.WorkspaceUser, {
			get: () => ({
				userDetails: {
					userId: this.state.userDetails?.userId,
					userDisplayName: this.state.userDetails?.userDisplayName,
				},
				userPreferences: this.state.userPreferences,
				isIWSEnabled: this.state.isIWSEnabled,
				isDaasVisionEnabled: this.state.isDaasVisionEnabled,
				isActivityManagerEnabled: this.state.isActivityManagerEnabled,
			}),
		});
	}

	public render() {
		const {
			userDetails,
			userDetailsLoader,
			userPreferences,
			isIWSEnabled,
			userBranding,
			isDaasVisionEnabled,
			isActivityManagerEnabled,
			error,
			isValidatedReturnUser,
		} = this.state;
		const loading = !error && userDetails === null;

		return (
			<Context.Provider
				value={{
					userDetails,
					userDetailsLoader,
					userPreferences,
					userBranding,
					isIWSEnabled,
					isDaasVisionEnabled,
					isActivityManagerEnabled,
					isValidatedReturnUser,
					loading,
					error: this.state.error,
					clear: this.clear,
					update: this.update,
					logOff: this.logOff,
					hasLoggedIn: this.hasLoggedIn,
				}}
			>
				{this.props.children}
			</Context.Provider>
		);
	}

	public componentDidMount() {
		setUserNameFunction(this.updateUsername);
		addNativeLogoutCallback(this.clearUsername);
	}

	public componentWillUnmount() {
		container.unregister(InternalPlatformDependencies.UserDetails);
		container.unregister(PlatformDependencies.WorkspaceUser);
	}

	private clearUsername = () => {
		this.updateUsername('');
	};

	private update: UserContext['update'] = context => {
		this.setState(state => ({
			...state,
			...context,
		}));
	};

	private updateUsername = (username: string) => {
		this.setState(state => ({
			userDetails: {
				...state.userDetails,
				userDisplayName: username,
				isValidUserDisplayName: !!username,
			},
		}));
	};

	private clear = () => {
		this.setState(initialState);
	};

	private logOff = (sessionExpired?: boolean) => {
		this.props.history.push({
			pathname: logoutRoute.getUrl(),
			search: sessionExpired ? '?userHint=inactivity' : null,
		});
	};

	private hasLoggedIn = async (): Promise<boolean> => {
		// User Details loader is the promise which will be resolved when userDetails are loaded.
		await this.state.userDetailsLoader;

		// As of today, we are considering user is logged in if we have userThumbprint. This is obtained only after user logs in.
		// But we will have partial userDetails from cache which doesn't guarantee that user is logged in.

		// In offline mode, the minimal required user details can be fetched from cache and this is good to consider that user has logged in in offline mode
		return !!this.state.userDetails?.userThumbprint || !environment.citrixCloudConnected;
	};
}

export const UserProvider = (props: { children?: React.ReactNode }) => {
	const history = useHistory();
	const { workspaceConfiguration } = useConfigurationContext();
	return (
		<_UserProvider history={history} workspaceConfiguration={workspaceConfiguration}>
			{props.children}
		</_UserProvider>
	);
};
