import { ClientInstance } from '@citrite/http';
import { EncryptedCacheBucket, EndpointSupplier } from '@citrite/workspace-ui-platform';
import URI from 'urijs';
import { logAdminNotificationError } from './logAdminNotificationError';

export interface AdminNotificationMessage {
	[languageCode: string]: string;
}

export enum BannerPosition {
	Top = 'Top',
	Bottom = 'Bottom',
}

export interface AdminNotification {
	id: string;
	name: string;
	message: AdminNotificationMessage;
	type: string;
	startTime: string;
	expireTime: string;
	position?: BannerPosition;
}

export interface AdminNotificationResponse {
	items: AdminNotification[];
}

export interface AdminNotificationClient {
	fetchNotifications(): Promise<AdminNotification[]>;
	fetchNotificationsCacheFirst(): Promise<AdminNotification[]>;
}

export const ANS_CACHE_KEY = 'adminNotifications';

export enum Type {
	Banner = 'banner',
}

export const createAdminNotificationClient = (
	endpointSupplier: EndpointSupplier,
	ssoProxyClient: ClientInstance,
	cache: EncryptedCacheBucket,
	customerId: string,
	storeGuid: string
): AdminNotificationClient => {
	const fetchNotifications = async () => {
		const endpoint = await endpointSupplier.getEndpoint('AdminNotificationService');
		const endpointUri = new URI(endpoint);

		const adminNotificationResponse = await ssoProxyClient.get<AdminNotificationResponse>(
			endpointUri.href(),
			{
				headers: {
					'Citrix-InstanceId': storeGuid,
					'Citrix-CustomerId': customerId,
				},
			}
		);

		return adminNotificationResponse.items;
	};

	const fetchNotificationsCacheFirst = async () => {
		return cache.fetchEncryptedCacheFirst<AdminNotification[]>(
			ANS_CACHE_KEY,
			async cancel => {
				try {
					return await fetchNotifications();
				} catch (e) {
					logAdminNotificationError(e);
					cancel();
				}

				return [];
			}
		);
	};

	return { fetchNotifications, fetchNotificationsCacheFirst };
};
