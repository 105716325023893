import React from 'react';
import { fromThemeColors, ThemeColor } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { GenericErrorBoundary } from 'App/GenericErrorBoundary';
import { Actions } from 'App/Header/Actions';
import { SmallFormTitle } from 'App/Header/SmallFormTitle';
import { Title } from 'App/Header/Title';
import { ItemSearchBar } from 'App/Masthead/ItemSearchBar';
import { Banner } from './Banner';
import { useFormFactor } from './useFormFactor';

const StyledHorizontalLine = styled.hr`
	margin: 0;
	border-top: 1px solid ${fromThemeColors(ThemeColor.border)};
`;

const StyledContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const _DaasHeader = () => {
	const { isLargeFormFactor } = useFormFactor();
	const getSearchBar = () => <ItemSearchBar />;
	return (
		<>
			<Banner>
				<StyledContent>
					{isLargeFormFactor ? <Title /> : <SmallFormTitle />}
					{isLargeFormFactor && getSearchBar()}
					<Actions />
				</StyledContent>
				{!isLargeFormFactor && getSearchBar()}
			</Banner>
			<StyledHorizontalLine />
		</>
	);
};

export const DaasHeader = () => {
	return (
		<GenericErrorBoundary renderProp={() => <_DaasHeader />} name="DaasHeaderError" />
	);
};
