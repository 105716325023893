import React from 'react';
import { layout, palette } from '@citrite/web-ui-component';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { CitrixBranding } from 'App/CitrixBranding';
import { ServerName } from 'App/ServerName';

const StyledFooterDiv = styled.div<{
	bottomOffset: number;
}>`
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: ${layout.mediumSpace};
	width: 100%;
	max-width: 1072px;
	justify-content: space-between;
	align-items: center;
	left: 50%;
	transform: translateX(-50%);
	box-sizing: border-box;
	@media (max-width: 1200px) {
		padding-left: 64px;
		padding-right: 64px;
	}
	${props =>
		props.bottomOffset &&
		`
		bottom: ${props.bottomOffset}px;
	`}
`;
/**	CSS explanation:
 * max width of the Footer section is 1072px (same as the Tabs section)
 */

const StyledHorizontalLine = styled.hr`
	margin: ${layout.smallSpace} 0;
	border-top: 1px solid ${palette.grey600};
	border-bottom: 0px;
	border-left: 0px;
	width: 100%;
`;

const StyledInnerContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin: 0;
`;

const elements: JSX.Element[] = [
	<CitrixBranding key="citrix-branding" />,
	<ServerName key="server-name" />,
];

export const AppFooter = () => {
	const theme = useTheme();
	const bottomOffset = theme?.mastheadOffset?.daasBottomOffset;

	return (
		<StyledFooterDiv bottomOffset={bottomOffset}>
			<StyledHorizontalLine />
			<StyledInnerContainer>
				{elements.map(element => (
					<React.Fragment key={element.key}>{element}</React.Fragment>
				))}
			</StyledInnerContainer>
		</StyledFooterDiv>
	);
};
