import React from 'react';
import styled from '@emotion/styled';
import { useFormFactor } from 'App/Header/useFormFactor';
import { Container } from 'App/Screen/Container';
import { SettingsHeader } from 'App/Screen/SettingsHeader';
import { SettingsSecondaryNavigation } from 'App/Screen/SettingsSecondaryNavigation';

const StyledDiv = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

interface Props {
	children: React.ReactNode;
}

export const SettingsContainer = ({ children }: Props) => {
	const { isLargeFormFactor } = useFormFactor();
	return (
		<Container>
			{isLargeFormFactor && <SettingsHeader />}
			<StyledDiv>
				<SettingsSecondaryNavigation />
				{children}
			</StyledDiv>
		</Container>
	);
};
