import axios from 'axios';
import { logError } from 'remoteLogging';
import { environment } from 'Environment';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
import {
	customHeadersForCallHome,
	generateUrlForAxios,
} from 'LeaseWorkflow/AxiosHelperForHtml5Shield';
import { shieldCryptoModuleInstance } from 'LeaseWorkflow/ShieldCryptoModule';
import { traceManager } from 'Tracing';

interface ObjectsFromLocalStorage {
	wsuiLastKnowConfig: Object;
	lastUserDetails: Object;
}

interface LeaseCallHomeInfo {
	DEVICE_ID_CONST: string;
	DEFAULT_CLIENT_TYPE: string;
	OS_TYPE: string;
	LEASE_TYPE_VALUE: string;
	LEASE_VERSION_VALUE: string;
	ENDPOINT_DEVICE_CLIENT_NAME: string;
	ENDPOINT_DEVICE_ADDRESS: string;
	ENDPOINT_CWA_VERSION: string;
	WSUI_LAST_KNOWN_CONFIGURATION: string;
	CONF_ITEM_STORE_IDENTIFIERS: string;
	CONF_ITEM_STORE_GUID: string;
	CONF_ITEM_CUSTOMER_ID: string;
	LAST_USER_DETAILS: string;
	LEASE_SYNCING_ENDPOINTS: string;
	LEASE_PROPERTIES_KEY: string;
	leaseFilePath: string;
	metadataPath: string;
	objectsForShield: ObjectsFromLocalStorage;
}

export const leaseCallHomeInfo: LeaseCallHomeInfo = {
	DEVICE_ID_CONST: 'deviceId',
	DEFAULT_CLIENT_TYPE: 'ica30',
	OS_TYPE: 'HTML5',
	LEASE_TYPE_VALUE: 'XD',
	LEASE_VERSION_VALUE: '1.1',
	ENDPOINT_DEVICE_CLIENT_NAME: 'ClientName',
	ENDPOINT_DEVICE_ADDRESS: '0.0.0.0',
	ENDPOINT_CWA_VERSION: '1.0',
	WSUI_LAST_KNOWN_CONFIGURATION: 'WSUI-LastKnownConfiguration',
	CONF_ITEM_STORE_IDENTIFIERS: 'storeIdentifiers',
	CONF_ITEM_STORE_GUID: 'storeGuid',
	CONF_ITEM_CUSTOMER_ID: 'customerId',
	LAST_USER_DETAILS: 'last-user-details',
	LEASE_SYNCING_ENDPOINTS: 'lease_sync_endpoints',
	LEASE_PROPERTIES_KEY: 'LeasePropertiesKey',
	leaseFilePath: '',
	metadataPath: '',
	objectsForShield: { wsuiLastKnowConfig: null, lastUserDetails: null },
};

class CallHomeService {
	private callHomeUrl: string;
	private leasePropertiesUrl: string;
	private clientType: string;
	private osType: string;
	private leaseTypeValue: string;
	private leaseVersionValue: string;
	private endpointDeviceIpAddr: string;
	private endpointClientVer: string;
	private isFirstCallHomeTriggered: boolean;
	private lastCallHomeEndPointsTime: number;

	public constructor() {
		this.callHomeUrl = null;
		this.leasePropertiesUrl = null;
		this.clientType = leaseCallHomeInfo.DEFAULT_CLIENT_TYPE;
		this.osType = leaseCallHomeInfo.OS_TYPE;
		this.leaseTypeValue = leaseCallHomeInfo.LEASE_TYPE_VALUE;
		this.leaseVersionValue = leaseCallHomeInfo.LEASE_VERSION_VALUE;
		this.endpointDeviceIpAddr = leaseCallHomeInfo.ENDPOINT_DEVICE_ADDRESS;
		this.endpointClientVer = leaseCallHomeInfo.ENDPOINT_CWA_VERSION;
		this.isFirstCallHomeTriggered = false;
		this.lastCallHomeEndPointsTime = 0;
	}

	public async performCallHomeRequest(): Promise<void> {
		try {
			traceManager.logInfo(`starting workflow for Call Home, Date: ${Date.now()}`);
			const currentTime = new Date().getTime();
			if (
				!this.callHomeUrl ||
				!this.leasePropertiesUrl ||
				this.isCallHomeEndpointsOver24Hours(currentTime)
			) {
				this.lastCallHomeEndPointsTime = currentTime;
				//get the call home and lease properties endpoints
				const urlForLeasingService =
					this.extractUrlFromLastKnownConfiguration('leasingService');
				if (!urlForLeasingService) {
					return;
				}
				const responseForCallHomeEndpoints = await axios.get(
					generateUrlForAxios(urlForLeasingService),
					{
						headers: customHeadersForCallHome(urlForLeasingService),
					}
				);
				if (
					!this.responseHandlerForCallHomeEndpoints(responseForCallHomeEndpoints.data)
				) {
					return;
				}
			}

			//get the lease properties
			const responseForLeaseProperties = await axios.get(
				generateUrlForAxios(this.leasePropertiesUrl),
				{
					headers: customHeadersForCallHome(this.leasePropertiesUrl),
				}
			);
			if (!this.responseHandlerForLeaseProperties(responseForLeaseProperties.data)) {
				return;
			}

			//trigger call home request
			await axios.post(
				generateUrlForAxios(this.callHomeUrl),
				this.prepareCallHomePayload(),
				{
					headers: customHeadersForCallHome(this.callHomeUrl),
				}
			);

			//get the lease endpoints
			const leaseSyncServiceURL =
				this.extractUrlFromLastKnownConfiguration('leasingSyncService');
			if (!leaseSyncServiceURL) {
				return;
			}
			const responseForLeaseEndpoints = await axios.get(
				generateUrlForAxios(leaseSyncServiceURL),
				{
					headers: customHeadersForCallHome(leaseSyncServiceURL),
				}
			);
			this.responseHandlerForLeaseEndpoints(responseForLeaseEndpoints.data);
		} catch (error) {
			if (environment.citrixCloudConnected) {
				logError(error, {
					tags: {
						feature: 'html5-shield',
					},
				});
			}
		}
	}

	//HDX-51947: only send a message for endpoints within 24h during this login
	private isCallHomeEndpointsOver24Hours(currentTime: number): boolean {
		return currentTime - this.lastCallHomeEndPointsTime >= 24 * 3600 * 1000;
	}

	private extractUrlFromLastKnownConfiguration(urlName: string): string {
		try {
			const urlLinks =
				leaseCallHomeInfo.objectsForShield.wsuiLastKnowConfig['endpointsServices'][
					'endpointsService'
				];
			let urlExtracted = '';
			for (let i = 0; i < urlLinks.length; i++) {
				const url = urlLinks[i];
				if (url['name'] === urlName) {
					urlExtracted = url['discoveryUrl'];
					break;
				}
			}
			return urlExtracted;
		} catch (err) {
			return '';
		}
	}

	/**
	 * response format:
	 * {"customEndpoints":[{"id":"CallHome","url":"https://www.example.com"},{"id":"Properties","url":"https://www.example.com"}]}
	 */
	private responseHandlerForCallHomeEndpoints(responseJson: Object): boolean {
		if (!responseJson || !responseJson['customEndpoints']) {
			return false;
		}
		const endpointsObj = responseJson['customEndpoints'];
		for (let i = 0; i < endpointsObj.length; i++) {
			const endpoint = endpointsObj[i];
			if (endpoint.id === 'Properties') {
				this.leasePropertiesUrl = endpoint.url;
			} else if (endpoint.id === 'CallHome') {
				this.callHomeUrl = endpoint.url;
			}
		}
		if (!this.callHomeUrl || !this.leasePropertiesUrl) {
			return false;
		}
		return true;
	}

	/**
	 * response format:
	 * {"properties":{"resourceLeasingEnabled":true,"deleteResourceLeasesOnLogOff":true,"bypassAuthForCachedResources":true,"resourceLeaseValidityPeriodInDays":7,
	 * "telemetryHeadlessLaunchEnabled":true,"telemetryLaunchShadowDelayMins":1,"telemetryLaunchMinTimeIntervalMins":720},"policies":[{"serviceName":"leasingservice",
	 * "policy":{"frequency":{"minimumInMinutes":360}}},{"serviceName":"leasingsyncservice","policy":{"frequency":{"minimumInMinutes":180}}}]}
	 */
	private responseHandlerForLeaseProperties(responseJson: Object): boolean {
		if (!responseJson || !responseJson['properties']) {
			return false;
		}
		if (!this.isFirstCallHomeTriggered) {
			let leaseServiceFrequency = 6 * 3600 * 1000; //default value 6 hours
			const policies = responseJson['policies'];
			if (!!policies) {
				for (let i = 0; i < policies.length; i++) {
					if (policies[i]['serviceName'] === 'leasingservice') {
						leaseServiceFrequency =
							policies[i]['policy']['frequency']['minimumInMinutes'] * 60 * 1000;
						break;
					}
				}
			}
			setInterval(() => {
				this.performCallHomeRequest();
			}, leaseServiceFrequency);
		}
		if (responseJson['properties']['resourceLeasingEnabled'] === true) {
			setInLocalStorage(
				leaseCallHomeInfo.LEASE_PROPERTIES_KEY,
				JSON.stringify(responseJson)
			);
		}
		return responseJson['properties']['resourceLeasingEnabled'];
	}

	/**
	 * response format:
	 * {"customEndpoints":[{"id":"GetRootMetadata","url":"https://www.example.com"},{"id":"GetChildrenMetadata","url":"https://www.example.com"},
	 * {"id":"GetFile","url":"https://www.example.com"}]}
	 */
	private responseHandlerForLeaseEndpoints(responseJson: Object): boolean {
		if (!responseJson || !responseJson['customEndpoints']) {
			return false;
		}
		const endpointsData = {};
		const endpointsParsed = responseJson['customEndpoints'];
		for (let i = 0; i < endpointsParsed.length; i++) {
			endpointsData[endpointsParsed[i]['id']] = endpointsParsed[i]['url'];
		}
		setInLocalStorage(
			leaseCallHomeInfo.LEASE_SYNCING_ENDPOINTS,
			JSON.stringify(endpointsData)
		);
		if (!this.isFirstCallHomeTriggered) {
			this.isFirstCallHomeTriggered = true;
			import('./StartCLSync').then(clsyncModule => {
				clsyncModule.performCLSync();
			});
		}
		return true;
	}

	private prepareCallHomePayload() {
		const result = shieldCryptoModuleInstance.getPublicKey();
		const publicKey = JSON.parse(result);
		return {
			connectionLeaseTypes: [
				{
					leaseType: this.leaseTypeValue,
					leaseVersion: this.leaseVersionValue,
				},
			],
			endpointDeviceId: shieldCryptoModuleInstance.getDeviceId(),
			endpointDevicePublicKey: publicKey,
			endpointDeviceClientType: this.clientType,
			endpointDeviceClientName: getFromLocalStorage(
				leaseCallHomeInfo.ENDPOINT_DEVICE_CLIENT_NAME
			),
			endpointDeviceAddress: this.endpointDeviceIpAddr,
			endpointDeviceOSType: this.osType,
			endpointClientVersion: this.endpointClientVer,
		};
	}
}

export const callHomeService = new CallHomeService();
