import { browser, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { environment } from 'Environment';
import {
	currentPlatform,
	isChromeOS,
	isDesktop,
	isLinux,
	isMobileDevice,
	isMobileOrTabletDevice,
	urlParams,
} from 'Environment/launchResource/device/currentPlatform';
import { shouldUseServerConfigForProtocolHandler } from 'Environment/launchResource/device/platformSpecificFeatureFlags';
import { platform } from '../constants';
export * from 'Environment/launchResource/device/currentPlatform';

function onPremSpecificProtocolHandlerRegex(config: WorkspaceConfiguration): string {
	//For WSUI Cloud version we don't support Linux and CrOs  via Protocol handler.
	if (!IS_ON_PREM) {
		return '';
	}

	const getPlatformSpecificRegex = (
		platformCheck: boolean,
		platformName: string
	): string => {
		if (
			platformCheck &&
			config?.pluginAssistant?.protocolHandler?.platforms?.includes(platformName)
		) {
			return `|${platformName}`;
		}

		return undefined;
	};

	//Return either "|Linux"  or "|CrOS" or ""  based on the platforms in the store config
	return (
		getPlatformSpecificRegex(isLinux(), 'Linux') ||
		getPlatformSpecificRegex(isChromeOS(), 'CrOS') ||
		getPlatformSpecificRegex(isAndroidBrowser(), 'Android') ||
		getPlatformSpecificRegex(currentPlatform === platform.ios, 'iPhone') ||
		getPlatformSpecificRegex(currentPlatform === platform.ipadOS, 'iPad') ||
		''
	);
}

export function isProtocolHandlerSupported(config: WorkspaceConfiguration) {
	if (shouldUseServerConfigForProtocolHandler(config)) {
		return isUserAgentMatchedByRegex(config?.pluginAssistant?.protocolHandler?.platforms);
	}

	const legacyRegex = `(Macintosh|Windows NT${onPremSpecificProtocolHandlerRegex(
		config
	)}).*(Firefox|Chrome|Safari|Trident|Edge)`;

	return isUserAgentMatchedByRegex(legacyRegex);
}

// Checks if any of the supplied regexes match the browser user agent string.
// The caller supplies the regexes as a semi-colon (or comma) separated list of regex strings.
// The matches are performed case-insensitively.
export function isUserAgentMatchedByRegex(regexList: string) {
	try {
		const regexArray = regexList.split(/;|,/);
		for (let i = 0; i < regexArray.length; i++) {
			if (regexArray[i].trim().length === 0) {
				continue;
			}
			const regex = new RegExp(regexArray[i], 'i'); // Make it case insensitive
			if (regex.test(navigator.userAgent)) {
				return true;
			}
		}
	} catch (ex) {}

	return false;
}

export function isNativeClient() {
	return environment.isNative;
}

export function isAndroidBrowser() {
	const desktopOverrideFlag = urlParams && urlParams.indexOf('-desktop') !== -1;
	return (
		!desktopOverrideFlag &&
		!environment.isNative &&
		(currentPlatform === platform.android || currentPlatform === platform.androidTablet)
	);
}

// checks if client is native and device is android mobile or iOS mobile(excludes iPad and android tablet)
export function isNativeMobileClient() {
	return environment.isNative && isMobileDevice();
}

// checks if client is native and device is android mobile or iOS mobile(includes iPad and android tablet)
export function isNativeMobileOrTabletClient() {
	return environment.isNative && isMobileOrTabletDevice();
}
export function isDesktopBrowser() {
	return isDesktop() && !isNativeClient();
}
export function isNativeWidget() {
	return currentPlatform === platform.nativeWidget;
}

//allow-unused-export
export function isMobileWorkspace() {
	return isMobileOrTabletDevice();
}

export function isEdge() {
	/*
	userAgent for Edge: Mozilla/5.0 (Windows NT 10.0; Win64; x64) 
		AppleWebKit/537.36 (KHTML, like Gecko) 
		Chrome/92.0.4515.131 Safari/537.36 Edg/92.0.902.67
	*/
	return !!navigator.userAgent.match(/(Edg)/i);
}

export function userAgentContainsIWSEnabled() {
	return !!navigator.userAgent.match(/IWSEnabled/i);
}

export function isFirefox() {
	return browser.isFirefox();
}

// allow-unused-export
export function isAndroidNative(): boolean {
	return (
		environment.isNative &&
		(currentPlatform === platform.android || currentPlatform === platform.androidTablet)
	);
}

export const getDeviceName = (): string => {
	return platform[currentPlatform];
};

export const getDeviceType = () => {
	return environment.nativeCapabilities?.platform?.devicetype || getDeviceName();
};
