export const AotStrings = {
	enumeration: {
		category: 'App Enumeration',
		fetchResources: 'Workspace User Interface started request to fetch list of Resources',
		resourcesLoadedFromCache:
			'Workspace User Interface obtained a list of $0 resources from Workspace User Interface Cache',
		resourcesLoadedFromCacheAndNetwork:
			'Workspace User Interface obtained a list of $0 resources from Workspace User Interface Cache and network with enumeration result: $1',
	},
	launch: {
		category: 'App Launch',
		localAppLaunch: 'Workspace User Interface Requested Launch of Local App $0 ',
		localAppLaunchFailed:
			'Workspace User Interface Launch Failed due to Reason $0 for Local App: $1',
		icaAppLaunch: 'Workspace User Interface Requested Launch of ICA App $0 ',
		icaAppLaunchFailedWithBE:
			'Workspace User Interface Launch Failed with Browser extension due to Reason $0 for ICA App: $1',
		icaAppLaunchFailedWithChromeApp:
			'Workspace User Interface Launch Failed with Chrome app due to Reason $0 for ICA App: $1',
		shieldLaunchFailed:
			'Workspace User Interface shield launch failed due to Reason $0 for ICA App: $1',
		icaAppLaunchFailed:
			'Workspace User Interface Launch Failed due to Reason $0 for ICA App: $1',
	},
	bridge: {
		category: 'Native Bridge formation',
		nativeBridgeInitComplete:
			'Native bridge initialisation complete between Workspace User Interface and $0 platform',
		nativeBridgeStorageKeyReadError:
			'Native bridge failed to read local storage data for key $0. Workspace User Interface may render incorrect when offline.',
	},
};
