import React from 'react';
import { palette, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';

const StyledMessageSpan = styled.span`
	font-size: ${typography.sizes.xsml};
	font-weight: ${typography.weights.regular};
	color: ${palette.grey900};
	padding: 4px 4px 0 0;
	height: 12px;
`;

export const ServerName = () => {
	const resourceContext = useLoadableResourceContext()?.value;
	const serverName = resourceContext?.serverName;
	return <StyledMessageSpan>{serverName}</StyledMessageSpan>;
};
