import {
	AdminNotification,
	BannerPosition,
} from 'AdminNotificationService/createAdminNotificationClient';
import { detectLanguage } from 'javascript/sf/detectLanguage';

export function getBannerPositionFromFormFactor(
	position: BannerPosition,
	isLargeFormFactor: boolean
) {
	return !isLargeFormFactor ? BannerPosition.Top : position;
}

/* 
Since the admin console is sending the encoded message with 
mixed formating viz some brackets < > are left intact while 
others are changed to &lt, &gt , the DOMParser is not suitable 
and we have to use this document.createElement('textarea');
*/

const encodedElement = document.createElement('textarea');
function decodeMessage(encodedString: string) {
	encodedElement.innerHTML = encodedString;
	return encodedElement.value.toString();
}

export function shouldDisplayBanner(
	intendedPosition: BannerPosition,
	bannerPosition: BannerPosition,
	isLargeScreen: boolean
) {
	if (isLargeScreen) {
		return shouldDisplayBannerOnLargeScreen(bannerPosition, intendedPosition);
	} else {
		return shouldDisplayBannerOnSmallScreen(bannerPosition, intendedPosition);
	}
}

function shouldDisplayBannerOnLargeScreen(
	bannerPosition: BannerPosition,
	intendedPosition: BannerPosition
): boolean {
	return (
		(bannerPosition === BannerPosition.Top && intendedPosition === BannerPosition.Top) ||
		(bannerPosition === BannerPosition.Bottom &&
			intendedPosition === BannerPosition.Bottom)
	);
}

function shouldDisplayBannerOnSmallScreen(
	bannerPosition: BannerPosition,
	intendedPosition: BannerPosition
) {
	return (
		(bannerPosition === BannerPosition.Top && intendedPosition === BannerPosition.Top) ||
		(bannerPosition === BannerPosition.Bottom && intendedPosition === BannerPosition.Top)
	);
}

export async function createNotificationMessage(
	adminNotification: AdminNotification,
	isDaasVisionEnabled: boolean
) {
	const allowedTags = ['b', 'strong', 'em', 'i', 'u'];
	const isHtmlDecodeEnabledForDaasVision = isDaasVisionEnabled;
	if (isHtmlDecodeEnabledForDaasVision) {
		allowedTags.push('br');
	}

	const messageKeys = Object.keys(adminNotification.message);
	const userLanguageCode = detectLanguage();
	const preferredLanguageCode = messageKeys.includes(userLanguageCode)
		? userLanguageCode
		: messageKeys.find(key => key.startsWith(userLanguageCode)) || 'en';

	const notificationMessage = isHtmlDecodeEnabledForDaasVision
		? decodeMessage(adminNotification.message?.[preferredLanguageCode])
		: adminNotification.message?.[preferredLanguageCode];
	const DOMPurify = await import('dompurify');
	const sanitizedMessage = DOMPurify.default.sanitize(notificationMessage, {
		ALLOWED_TAGS: allowedTags,
		ALLOWED_ATTR: [],
	});
	return sanitizedMessage;
}
