import { Environment } from 'Environment/Environment';
import { GenericNativeBridgeManager } from 'Environment/NativeBridgeManager/genericNativeBridgeManager';
import { OnpremNativeBridgeManager } from 'Environment/NativeBridgeManager/onpremNativeBridgeManager';

// allow-unused-export: needed for unit tests
export { OnpremNativeBridgeManager, GenericNativeBridgeManager };

export interface INativeBridgeManager {
	supportsNativeOfflineCapability(): boolean;
	networkCallsAllowed(): boolean;
	setAllowNetworkCalls(allowNwCalls: boolean): void;
	canGoOnlineDuringBridgeCreation(env: Environment): boolean;
	canGoOnlineFromBridgeAjaxCall(): boolean;
	allowCacheUse(): boolean;
	makeOnlineCall(
		operationName: string,
		operation: () => void,
		cleanup: () => void
	): Promise<boolean>;
}

export const nativeBridgeManager = IS_ON_PREM
	? OnpremNativeBridgeManager.getInstance()
	: new GenericNativeBridgeManager();
