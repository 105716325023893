import { CITRIX_TRANSACTIONID_HEADER, postAsFormData } from '@citrite/http';
import { clientType, getClientType } from 'Environment/getClientType';
import { HTML5Client } from 'Environment/launchResource/html5Client';
import { launchFailed } from 'Environment/launchResource/resourceLaunch';
import { X_CITRIX_APPPROTECTION } from 'javascript/interceptors/Headers';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { ChromeAppLaunchOptions, postICADataErrorToChromeApp } from './chromeApp';
import { isCitrixChromeApp } from './device';

export interface LaunchHtml5Options {
	resource: Resource;
	html5Client: HTML5Client;
	chromeAppOptions: ChromeAppLaunchOptions;
	jsonParams: object;
	operationId?: string;
	launchCancellationToken?: string;
}

export function launchHtml5(options: LaunchHtml5Options): Promise<string> {
	const { resource, jsonParams, launchCancellationToken } = options;
	const postData: any = {
		launchMechanism: 'html5',
		...(launchCancellationToken && { LaunchCancellationToken: launchCancellationToken }),
	};

	return postAsFormData<string>(
		resource.launchurl,
		{
			...postData,
			...jsonParams,
		},
		{
			headers: options.operationId
				? {
						[CITRIX_TRANSACTIONID_HEADER]: options.operationId,
				  }
				: undefined,
		}
	)
		.then(data => {
			// Non-ICA responses come back as objects.
			if (typeof data === 'object') {
				return handleUnsuccessfulLaunch(options, data);
			} else {
				return data;
			}
		})
		.catch((error: Error) => handleLaunchError(options, error));
}

function handleUnsuccessfulLaunch(options: LaunchHtml5Options, data: any) {
	const { status } = data;
	const { resource, html5Client, chromeAppOptions } = options;
	switch (status) {
		case 'retry':
			html5Client.sendRetry();
			return new Promise<string>(resolve => {
				setTimeout(() => {
					resolve(launchHtml5(options));
				}, parseFloat(data.pollTimeout) * 1000);
			});
		case 'failure':
			html5Client.setErrorId(data.errorId);
			return Promise.reject(launchFailed(resource, data.errorId, chromeAppOptions));
		default:
			return Promise.reject(new Error('Unexpected Launch Status'));
	}
}

function handleLaunchError(options: LaunchHtml5Options, error: Error) {
	const { resource, chromeAppOptions } = options;

	if (isCitrixChromeApp() && chromeAppOptions) {
		postICADataErrorToChromeApp(
			error.message,
			resource,
			chromeAppOptions.uniqueIdentifier
		);
	}

	return Promise.reject(error);
}

//https://issues.citrite.net/browse/WSUI-9841
//This function has to be removed
export function appProtectionHeaders(isLaunch: boolean, isAppProtectionEnabled: boolean) {
	//if feature canary enabled ,then check for conditions to send app protection header.
	//sending app protection header in case of enumeration (isLaunch=false)
	//sending app protection header in case of launch(isLaunch=true) when client type is browser extension or chromeapp.
	if (isAppProtectionEnabled) {
		if (
			!isLaunch ||
			(isLaunch && getClientType() === clientType.browserextension) ||
			(isLaunch && getClientType() === clientType.chromeapp)
		) {
			return {
				[X_CITRIX_APPPROTECTION]: true,
			};
		} else {
			return {};
		}
	} else {
		return {};
	}
}
export function getLaunchStatus(
	resource: Resource,
	createFileFetchTicket: boolean,
	jsonParams: object,
	isAppProtectionEnabled?: boolean,
	operationId?: string,
	launchCancellationToken?: string
) {
	const postData: any = {};
	if (resource.displayNameDesktopTitle) {
		postData.displayNameDesktopTitle = resource.displayNameDesktopTitle;
	}
	postData.createFileFetchTicket = createFileFetchTicket;
	if (launchCancellationToken) {
		postData.LaunchCancellationToken = launchCancellationToken;
	}
	let headers: { [key: string]: boolean | string } = {
		...appProtectionHeaders(true, isAppProtectionEnabled), //sending isLaunch=true in case of launch call.
	};
	if (operationId) {
		headers = { ...headers, [CITRIX_TRANSACTIONID_HEADER]: operationId };
	}
	return postAsFormData<any>(
		resource.launchstatusurl,
		{
			...postData,
			...jsonParams,
		},
		{
			headers,
		}
	);
}
