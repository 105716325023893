import { useEffect, useState } from 'react';

export const useVisibility = () => {
	const [isVisible, setIsVisible] = useState(() => {
		return document.visibilityState === 'visible' && document.hasFocus();
	});

	useEffect(() => {
		const handleWindowFocus = () => {
			setIsVisible(document.visibilityState === 'visible' && document.hasFocus());
		};

		const handleWindowBlur = () => {
			setIsVisible(false);
		};

		document.addEventListener('visibilitychange', handleWindowFocus);
		window.addEventListener('focus', handleWindowFocus);
		window.addEventListener('blur', handleWindowBlur);

		return () => {
			document.removeEventListener('visibilitychange', handleWindowFocus);
			window.removeEventListener('focus', handleWindowFocus);
			window.removeEventListener('blur', handleWindowBlur);
		};
	}, []);

	return isVisible;
};
