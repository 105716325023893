import { getWithFullResponse } from '@citrite/http';
import {
	BackoffStrategy,
	hasFeatureCanary,
	resilientPromise,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { environment } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { isNativeWidget } from 'Environment/launchResource/device';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { digestString } from 'Workspace/Encryption/subtleEncryption';

const millisecPerDay = 1000 * 60 * 60 * 24;
const DaysEvery7Days = 7;
const DaysEvery30Days = 30;
const OneDay = 1;

export interface BlockingNotificationStored {
	hash: string;
	utcDateLastShown: number;
}

export enum Frequency {
	FirstLogin = 'FirstLogin',
	EveryDay = 'EveryDay',
	Every7Days = 'Every7Days',
	Every30Days = 'Every30Days',
}

export enum ModalAction {
	DoNotShow,
	Show,
}

export interface BlockingNotification {
	title: string;
	text: string;
	buttonName: string;
	frequency: Frequency;
}

export interface BlockingNotificationDto {
	enabled: boolean;
	title: string;
	body: string;
	button: string;
	frequency: Frequency;
}

function noPriorStoredNotification(
	storedNotificationData: BlockingNotificationStored
): boolean {
	return (
		!storedNotificationData ||
		!storedNotificationData.utcDateLastShown ||
		!storedNotificationData.hash
	);
}

async function isDifferentNotificationFromStored(
	stored: BlockingNotificationStored,
	blockingNotification: BlockingNotification
): Promise<boolean> {
	const notificationHash = await getHashOfBlockingNotification(blockingNotification);
	return notificationHash !== stored.hash;
}

function getTimeDifferenceInDays(strTimestampLastShown: number): number {
	const dateLastShown = new Date(strTimestampLastShown);
	const dateTimeNow = new Date(Date.now());
	return (dateTimeNow.getTime() - dateLastShown.getTime()) / millisecPerDay;
}

export async function shouldShowNotification(
	storageKey: string,
	blockingNotification: BlockingNotification,
	frequency: Frequency
): Promise<ModalAction> {
	const storedNotificationData: BlockingNotificationStored =
		getFromLocalStorage(storageKey);

	if (noPriorStoredNotification(storedNotificationData)) {
		return ModalAction.Show;
	}

	if (
		await isDifferentNotificationFromStored(storedNotificationData, blockingNotification)
	) {
		return ModalAction.Show;
	}

	//Same notification as before and frequency is FirstLogin
	if (frequency === Frequency.FirstLogin) {
		return ModalAction.DoNotShow;
	}

	const daysSinceModalLastShown = getTimeDifferenceInDays(
		storedNotificationData.utcDateLastShown
	);

	//Someone tampered with the local clock
	if (daysSinceModalLastShown < -1) {
		return ModalAction.Show;
	}

	if (frequency === Frequency.Every30Days && daysSinceModalLastShown < DaysEvery30Days) {
		return ModalAction.DoNotShow;
	}

	if (frequency === Frequency.Every7Days && daysSinceModalLastShown < DaysEvery7Days) {
		return ModalAction.DoNotShow;
	}

	if (frequency === Frequency.EveryDay && daysSinceModalLastShown < OneDay) {
		return ModalAction.DoNotShow;
	}

	return ModalAction.Show;
}

export async function getHashOfBlockingNotification(
	blockingNotification: BlockingNotification
): Promise<string> {
	return await digestString(JSON.stringify(blockingNotification));
}

export function hasScrolledDown<T extends HTMLElement>(
	node: T | null,
	scrollTop: number
) {
	if (!node) {
		return false;
	}
	return Math.abs(node.scrollHeight - scrollTop - node.clientHeight) <= 1;
}

export async function getBlockingNotificationData(
	blockingNotificationUrl: string
): Promise<BlockingNotificationDto> {
	const safeCallBlockingNotificationUrl = async (): Promise<BlockingNotificationDto> => {
		try {
			const apiResponse = await getWithFullResponse<BlockingNotificationDto>(
				blockingNotificationUrl
			);
			if (apiResponse.status !== 200) {
				throw new Error('Error in fetching blocking notification data');
			}

			return apiResponse.data;
		} catch (e) {
			logError(e);
			throw e;
		}
	};

	const promiseToGetNotificationData = resilientPromise(
		() => safeCallBlockingNotificationUrl(),
		{
			keepRetrying: false,
			delayMS: 300,
			backoffStrategy: BackoffStrategy.CONSTANT,
			maxAttempts: 3,
		}
	);

	return await promiseToGetNotificationData.catch(() => null);
}

export async function getBlockingNotificationDataAndModalAction(
	blockingNotificationUrl: string,
	storageKey: string
): Promise<{
	notification: BlockingNotification;
	modalAction: ModalAction;
}> {
	try {
		const { title, body, button, frequency } = await getBlockingNotificationData(
			blockingNotificationUrl
		);
		const notification: BlockingNotification = {
			title,
			text: body,
			buttonName: button,
			frequency,
		};

		const modalAction = await shouldShowNotification(
			storageKey,
			notification,
			notification.frequency
		);

		return { notification, modalAction };
	} catch (e) {
		return { notification: null, modalAction: ModalAction.DoNotShow };
	}
}

export function isBlockingNotificationEnabledByConfig(
	workspaceConfiguration: WorkspaceConfiguration
): boolean {
	if (!hasFeatureCanary(workspaceConfiguration, FeatureFlag.CustomBlockingNotification)) {
		return false;
	}

	return !!workspaceConfiguration?.storeProxy?.notifications?.blockingNotificationURL;
}

export function isFTUEnabledByConfig(
	workspaceConfiguration: WorkspaceConfiguration,
	userId: string
): boolean {
	const isFTUEnabled = IS_ON_PREM
		? workspaceConfiguration?.userInterface?.showFirstTimeUse === 'true'
		: environment.citrixCloudConnected;
	return !isNativeWidget() && isFTUEnabled && !!userId;
}
