import * as React from 'react';
import { useBreakpoints } from '@citrite/web-ui-component';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { useBranding } from 'Configuration/withBranding';
import { environment } from 'Environment';
import { isNativeWidget } from 'Environment/launchResource/device';

const mastheadHeightValue = 104;
const mobileMastheadHeightValue = isNativeWidget() ? 0 : 80;
export const bannerHeight = isNativeWidget() ? 100 : 60;

export const mastheadHeight = mastheadHeightValue + 'px';
export const mastheadLogoHeight = mastheadHeightValue - 1 + 'px';
export const mastheadHeightMobile = mobileMastheadHeightValue + 'px';
export const informationalBannerHeight = bannerHeight + 'px';

export interface MastheadOffset {
	setShouldOffsetMastheadBanner: (offsetMasthead: boolean) => void;
	informationalBannerOffset: number;
	mastheadOffset: number;
	mastheadOffsetMobile: number;
	setCustomBannerOffset: (offset: number, position: BannerPosition) => void;
	mastheadHeightWithCustomInfoBanner: string;
	topBannerOffset?: number;
	bottomBannerOffset?: number;
	daasBottomOffset?: number;
	setDaasBottomOffset?: (offset: number) => void;
}

export const ThemeProvider: React.FC = props => {
	const branding = useBranding();
	const breakpoint = useBreakpoints();
	const [shouldOffsetMastheadBanner, setShouldOffsetMastheadBanner] =
		React.useState(false);
	const [topBannerOffset, setTopBannerOffset] = React.useState(0);
	const [bottomBannerOffset, setBottomBannerOffset] = React.useState(0);
	const [daasBottomOffset, setDaasBottomOffset] = React.useState(0);

	const mastheadHeightWithCustomInfoBanner =
		(breakpoint.screenSize.onMobile ? mobileMastheadHeightValue : mastheadHeightValue) +
		'px';

	React.useEffect(() => {
		setShouldOffsetMastheadBanner(!environment.citrixCloudConnected);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [environment.citrixCloudConnected]);

	const informationalBannerOffset = shouldOffsetMastheadBanner ? bannerHeight : 0;

	const mastheadOffset =
		mastheadHeightValue + informationalBannerOffset + topBannerOffset;
	const mastheadOffsetMobile =
		mobileMastheadHeightValue + informationalBannerOffset + topBannerOffset;

	const setCustomBannerOffset = (offset: number, position: BannerPosition) => {
		if (position === BannerPosition.Top) {
			setTopBannerOffset(offset);
			setBottomBannerOffset(0);
		} else {
			setBottomBannerOffset(offset);
			setTopBannerOffset(0);
		}
	};

	return (
		<EmotionThemeProvider
			theme={{
				branding,
				mastheadOffset: {
					setShouldOffsetMastheadBanner,
					informationalBannerOffset,
					mastheadOffset,
					mastheadOffsetMobile,
					setCustomBannerOffset,
					mastheadHeightWithCustomInfoBanner,
					topBannerOffset,
					bottomBannerOffset,
					daasBottomOffset,
					setDaasBottomOffset,
				},
			}}
		>
			{props.children}
		</EmotionThemeProvider>
	);
};
