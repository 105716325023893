import * as React from 'react';
import { SettingsContainer } from 'App/Screen/SettingsContainer';
import { AppSettingsNamespace } from 'App/Settings/locales';
import {
	getSupportedChildRoutes,
	shouldShowAccountSettings,
} from 'App/Settings/SettingsUtils';
import { SecondaryContent } from 'Components/AppLayout';
import { withLocalizationNamespaces } from 'Components/withLocalizationNamespaces';
import {
	createChildRoutes,
	createLeftNavRoute,
	RoutedComponentProps,
} from 'Router/ReactRoute';

type Props = RoutedComponentProps;

function WorkspaceSettings(props: Props) {
	return (
		<SettingsContainer>
			<SecondaryContent>
				<ChildRoutes {...props} />
			</SecondaryContent>
		</SettingsContainer>
	);
}

const ChildRoutes = createChildRoutes(getSupportedChildRoutes());
const component = withLocalizationNamespaces(AppSettingsNamespace)(WorkspaceSettings);
export const settingsRoute = createLeftNavRoute({
	component,
	childRoutes: getSupportedChildRoutes(),
	isAvailable: props => shouldShowAccountSettings(props.workspaceConfiguration),
	path: '/settings',
	hasSecondaryNav: true,
	isLoading: props => props.resources.loading,
});
