import { useEffect, useState } from 'react';
import { first } from 'lodash';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import {
	Machine,
	MachineData,
	MachineError,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { getTimestamp, isStaleMachineData } from 'App/Activity/Utility';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const validPowerStates = [
	PowerState.ON,
	PowerState.OFF,
	PowerState.SUSPENDED,
	PowerState.UNKNOWN,
	'loading',
];

const getLatestMachineData = (machineData1: MachineData, machineData2: MachineData) => {
	let validMachineData = machineData1;
	if (
		!validMachineData ||
		getTimestamp(validMachineData?.lastUpdatedTime) <
			getTimestamp(machineData2?.lastUpdatedTime)
	) {
		validMachineData = machineData2;
	}

	if (isStaleMachineData(validMachineData?.lastUpdatedTime)) {
		validMachineData = null;
	}
	return validMachineData;
};

const getResourceState = (
	resource: Resource,
	machines: (Machine | MachineError)[],
	sessions: Session[]
) => {
	const machineDataFromMachineApi = (
		machines.find(({ resourceId }) => resourceId === resource.id) as Machine
	)?.machineData;
	const machineDataFromSessionApi = sessions.find(
		session => first(session.applications)?.resource?.id === resource.id
	)?.machineData;

	return (
		getLatestMachineData(machineDataFromMachineApi, machineDataFromSessionApi)
			?.powerState || PowerState.UNKNOWN
	);
};

export const useResourceState = (resource: Resource) => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const context = useResourceManagerContext();
	const [state, setState] = useState<PowerState | 'loading'>(PowerState.NOT_SUPPORTED);

	useEffect(() => {
		let newState: PowerState | 'loading';
		if (
			!resource.canQueryMachineState ||
			!resource.isdesktop ||
			!isMachinePowerStateEnabled
		) {
			newState = PowerState.NOT_SUPPORTED;
		} else if (
			context?.machineStatus?.loading ||
			context?.localSessions?.loading ||
			context?.remoteSessions?.loading ||
			context?.hibernatedSessions?.loading
		) {
			newState = 'loading';
		} else {
			newState = getResourceState(resource, context?.machineStatus?.machineData, [
				...context?.localSessions?.sessions,
				...context?.remoteSessions?.sessions,
				...context?.hibernatedSessions?.sessions,
			]);
		}
		if (!validPowerStates.includes(newState)) {
			newState = PowerState.NOT_SUPPORTED;
		}
		setState(newState);
	}, [
		context?.machineStatus,
		context?.localSessions,
		context?.remoteSessions,
		context?.hibernatedSessions,
		resource,
		isMachinePowerStateEnabled,
	]);

	return state;
};
