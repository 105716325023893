import React, { useContext } from 'react';
import _ from 'lodash';
import {
	LaunchOperationResponse,
	MachineOperationResponse,
	PowerState,
	SessionOperationResponse,
} from 'App/Activity/Network/ActionsUtil';
import { platform } from 'Environment/launchResource/constants';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export enum SessionAction {
	LOGOUT = 'log_out',
	DISCONNECT = 'disconnect',
}

export enum MachineAction {
	POWEROFF = 'poweroff',
	SHUTDOWN = 'shutdown',
	HIBERNATE = 'hibernate',
}

export enum LaunchAction {
	RESUME = 'resume',
	RECONNECT = 'reconnect',
	TRANSFER = 'transfer',
	LAUNCH = 'launch',
	RESTART = 'restart',
}

export enum ApplicationAction {
	TERMINATE = 'terminate',
}

export const UNKNOWN_STATE = 'unknown';

export type DesktopAction = LaunchAction | SessionAction | MachineAction;

// we get Applications from Session Response, we are using ResourceDetails in UI to store the application details
export interface ResourceDetails {
	iconUrl: string;
	id: string;
	resource?: Resource;
	name: string;
	localSession?: LocalSession;
}

export interface MachineData {
	machineId?: string;
	machineName: string;
	powerState?: PowerState;
	registrationState?: string;
	sessionSupport?: string;
	shutDownSupported?: boolean;
	suspendSupported?: boolean;
	turnOffSupported?: boolean;
	powerActionInProgress?: boolean;
	lastUpdatedTime?: string; // TODO: Create dep tkt to WSP and broker to include lastUpdatedTime as part of session list response and check on this as well
}

export interface Machine {
	resourceId: string;
	maxLastUpdatedTime: string;
	machineData: MachineData;
}

export interface MachineError {
	resourceId: string;
	errorId: string;
	machineData?: MachineData;
}

export enum DeviceType {
	WINDOWS = 'Windows',
	OTHERS = 'Others',
}

export enum SessionType {
	DESKTOP = 'Desktop',
	APPLICATION = 'Application',
}

export enum SessionConnectionState {
	CONNECTED = 'connected',
	DISCONNECTED = 'disconnected',
	ACTIVE = 'active',
}

export interface LocalSession {
	description?: string;
	id: string;
	name: string;
	type: string;
	isAppContainer?: boolean;
	parentId?: string;
	startTimeStamp?: string;
	supportedOperations: string[];
	state?: string;
}

export interface Session {
	// we get Applications from Session Response, we are using ResourceDetails in UI to store the application details
	applications: ResourceDetails[];
	clientName?: string;
	clientType?: string;
	connectionState: SessionConnectionState;
	deviceId: string;
	deviceType: DeviceType | platform;
	logoffDisconnectedSupported?: boolean;
	machineData: MachineData;
	sessionId: string;
	sessionStartTime?: string;
	userSessionType: SessionType;
	localSession?: LocalSession;
}
export interface Sessions {
	loading: boolean;
	sessions: Session[];
}

export interface MachineStatus {
	loading: boolean;
	machineData: (Machine | MachineError)[];
}

export interface ActiveSessionAction {
	session: Session;
	action: SessionAction;
	response: SessionOperationResponse;
}

export interface ActiveMachineAction {
	machine: MachineData;
	action: MachineAction;
	response: MachineOperationResponse;
}
export interface ActiveLaunchAction {
	session: Session;
	action: LaunchAction;
	response: LaunchOperationResponse;
}
export interface IResourceManagerContext {
	deviceId: string;
	localSessions: Sessions;
	remoteSessions: Sessions;
	hibernatedSessions: Sessions;
	machineStatus: MachineStatus;
	activeSessionActions: ActiveSessionAction[];
	activeMachineActions: ActiveMachineAction[];
	activeLaunchActions: ActiveLaunchAction[];
	refresh: (isSilentLoad?: boolean) => void;
	onDesktopAction: (action: DesktopAction, session: Session) => Promise<void>;
	onApplicationAction: (
		action: ApplicationAction,
		application: ResourceDetails,
		session: Session
	) => Promise<void>;
}
const defaultContext: IResourceManagerContext = {
	deviceId: '',
	localSessions: { loading: false, sessions: [] },
	remoteSessions: { loading: false, sessions: [] },
	hibernatedSessions: { loading: false, sessions: [] },
	machineStatus: { loading: false, machineData: [] },
	activeSessionActions: [],
	activeMachineActions: [],
	activeLaunchActions: [],
	refresh: () => {},
	onDesktopAction: async () => {},
	onApplicationAction: async () => {},
};

export const ResourceManagerContext =
	React.createContext<IResourceManagerContext>(defaultContext);
export const useResourceManagerContext = () => useContext(ResourceManagerContext);
