import { hasFeatureCanary, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { logError } from 'remoteLogging';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { clientType, getClientType } from 'Environment/getClientType';
import { isChrome, isEdge, isFirefox } from 'Environment/launchResource/device';
import { getFromLocalStorage } from 'javascript/sf/Storage';
import { callHomeService, leaseCallHomeInfo } from 'LeaseWorkflow/CallHomeService';
import { initConnectionLeaseManager } from 'LeaseWorkflow/ConnectionLeaseManagerService';
import { shieldCryptoModuleInstance } from 'LeaseWorkflow/ShieldCryptoModule';
import { traceManager } from 'Tracing';

let isHtml5ShieldEnabledAtInitialization = false;

export function isHTML5ShieldEnabled(wsuiConfig: WorkspaceConfiguration) {
	const { html5 } = wsuiConfig?.pluginAssistant || {};
	const ishtml5AlwaysEnabled = html5?.enabled === 'Always';
	return (
		(ishtml5AlwaysEnabled || getClientType() === clientType.html5) &&
		browsersSupportHtml5Shield() &&
		hasFeatureCanary(wsuiConfig, FeatureFlag.WsuiEnableHTML5Shield) &&
		isCloudStore()
	);
}

export async function initializeWasmForShieldFlow() {
	traceManager.logInfo('Initializing HTML5 Shield modules');
	await shieldCryptoModuleInstance.loadShieldCryptoWasm();
	await initConnectionLeaseManager();
	isHtml5ShieldEnabledAtInitialization = true;
}

export function callHomeForShield() {
	setTimeout(async () => {
		try {
			await shieldCryptoModuleInstance.generateKvlrCryptoKeypairForShield();
			await shieldCryptoModuleInstance.shieldInit();
			callHomeService.performCallHomeRequest();
		} catch (err) {
			logError(err, {
				tags: {
					feature: 'html5-shield',
				},
			});
		}
	}, 1000);
}

export function getHtml5ShieldStatus() {
	return isHtml5ShieldEnabledAtInitialization;
}

function browsersSupportHtml5Shield() {
	return isChrome() || isEdge() || isFirefox();
}

function isCloudStore() {
	const storeUrl = location.hostname.toLowerCase();
	const cloudDomains = [
		'cloud.com',
		'cloudburrito.com',
		'netscalergateway.net',
		'netscalergatewaystaging.net',
		'sharefile.com',
		'sharefilestaging.com',
	];
	return !!cloudDomains.find(value => {
		return storeUrl.includes(value);
	});
}

interface Policy {
	serviceName: string;
	policy: {
		frequency?: {
			minimumInMinutes?: number;
		};
	};
}

interface LeasingServicePropertiesResponse {
	policies?: Policy[];
	properties?: {
		resourceLeasingEnabled?: boolean;
		alwaysBypassAuthForCachedResources?: boolean;
		bypassAuthForCachedResources?: boolean;
		resourceLeaseValidityPeriodInDays?: number;
		telemetryHeadlessLaunchEnabled?: boolean;
		telemetryLaunchShadowDelayMins?: number;
		telemetryLaunchMinTimeIntervalMins?: number;
		deleteResourceLeasesOnLogOff?: boolean;
	};
}

export function shouldDeleteResourceLeasesOnLogOff(): boolean {
	const properties = getFromLocalStorage<LeasingServicePropertiesResponse>(
		leaseCallHomeInfo.LEASE_PROPERTIES_KEY
	)?.properties;
	let deleteResourceLeasesOnLogOff = true;
	if (properties) {
		deleteResourceLeasesOnLogOff = !!properties.deleteResourceLeasesOnLogOff;
	}
	return deleteResourceLeasesOnLogOff;
}

export function stopCLSync() {
	const clsync = globalThis['CtxWeb']['CLSyncEngine'];
	clsync['stopCLSync']();
}
