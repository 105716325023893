import React, { useEffect, useLayoutEffect, useState } from 'react';
import { t } from '@citrite/translate';
import { Button, LoadingPrimary, ModalProps, Tooltip } from '@citrite/web-ui-component';
import DOMPurify from 'dompurify';
import {
	Message,
	StyledModal,
	StyledModalBody,
	StyledModalFooter,
	StyledModalHeader,
	StyledModalTitle,
} from './SignInBlockingModal.styled';
import { BlockingNotification, hasScrolledDown } from './SignInBlockingModalUtils';

export interface Props extends ModalProps {
	handleClose: () => void;
	enabled: boolean;
	blockingNotification?: BlockingNotification;
}

export const SignInBlockingModal: React.FC<Props> = ({
	handleClose,
	blockingNotification,
	enabled,
}) => {
	const [allRead, setAllRead] = useState(false);
	const message = React.useRef(null);
	const [screenSize, setScreenSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const onResize = () => {
		setScreenSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	useEffect(() => {
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
			setAllRead(false);
		};
	}, []);

	useLayoutEffect(() => {
		if (blockingNotification) {
			const messageCurrent = message?.current;
			if (
				enabled === true &&
				messageCurrent &&
				messageCurrent.clientHeight === messageCurrent.scrollHeight
			) {
				setAllRead(true);
			}
		}
		return () => {
			setAllRead(false);
		};
	}, [blockingNotification, enabled, screenSize]);

	if (blockingNotification) {
		return (
			<StyledModal closeOnOverlayClick={false} closeOnEscape={false} isOpen={enabled}>
				<StyledModalHeader>
					<div>
						<StyledModalTitle>{blockingNotification.title}</StyledModalTitle>
					</div>
				</StyledModalHeader>
				<Message
					onScroll={handleScroll}
					ref={message}
					data-testid="sign-in-blocking-modal-scrollable-element"
				>
					<StyledModalBody>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: sanitizeMessage(),
							}}
							data-testid="sign-in-blocking-modal-text"
						/>
					</StyledModalBody>
				</Message>
				<StyledModalFooter>
					<Tooltip
						placement="right"
						isDisabled={allRead}
						hoverTrigger={
							<Button.Primary onClick={handleClose} disabled={!allRead}>
								{blockingNotification.buttonName}
							</Button.Primary>
						}
					>
						{t('javascript:sign_in_blocking_modal.disabled_button_tooltip', {
							buttonName: blockingNotification.buttonName,
						})}
					</Tooltip>
				</StyledModalFooter>
			</StyledModal>
		);
	}

	return (
		<StyledModal closeOnOverlayClick={false} closeOnEscape={false} isOpen={enabled}>
			<StyledModalHeader>
				<div></div>
			</StyledModalHeader>
			<Message
				onScroll={handleScroll}
				ref={message}
				data-testid="sign-in-blocking-modal-scrollable-element"
			>
				<StyledModalBody>
					<LoadingPrimary />
				</StyledModalBody>
			</Message>
			<StyledModalFooter>
				<div></div>
			</StyledModalFooter>
		</StyledModal>
	);

	function handleScroll(e: React.UIEvent<HTMLElement>) {
		if (allRead) {
			return;
		}
		if (!e?.target) {
			return;
		}
		const { scrollTop } = e.target as HTMLElement;
		setAllRead(hasScrolledDown(message.current, scrollTop));
	}

	function sanitizeMessage() {
		const allowedTags = [
			'b',
			'strong',
			'em',
			'i',
			'u',
			'br',
			'p',
			'h1',
			'h2',
			'h3',
			'h4',
			'h5',
			'h6',
		];
		const sanitizedMessage = DOMPurify.sanitize(blockingNotification?.text, {
			ALLOWED_TAGS: allowedTags,
			ALLOWED_ATTR: [],
		});
		return sanitizedMessage;
	}
};
