import { MSALAuthorizationResultType } from '@citrite/msal-browser';
import { NativePlatform, UserPreferences } from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent, trackEvent } from 'analytics';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { addBreadcrumb, logError } from 'remoteLogging';
import { AotType } from 'App/AotTrace';
import { DaasUIMetadata } from 'App/Screen';
import {
	AttemptLogoffResult,
	CanLogoffResult,
	Environment,
	GetOnlineStatusResult,
	LaunchResourceOptions,
} from 'Environment/Environment';
import { nativeBridgeManager } from 'Environment/NativeBridgeManager';
import { defaultPopupOptions, OpenWindowOptions } from 'Environment/window/BrowserWindow';
import {
	CITRIX_IGNORE_AUTH_CHALLENGE,
	X_CLOUD_OFFLINE_REQUEST,
} from 'javascript/interceptors/Headers';
import { FeedCardEventType } from 'userNotifications/EventSubscribers/EventTypes';
import { Event } from 'Workspace/EventBus';
import {
	ActivityCardResourceList,
	InstalledResource,
	InstalledResourceData,
	Resource,
	SiriRegisteredResource,
	SiriRegisteredResourceData,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';
import { citrixBrowserAnalyticsReporter } from 'Workspace/TelemetryEvents/citrixBrowser/createCitrixBrowserAnalyticsReporter';
import { authChallengeHeader, environment, GoOnlineResult, OnlineStatus } from './';
import { callAsyncNativeFunction, callSyncNativeFunction } from './callNativeFunction';
import { handleLaunchStatus, launchFailed } from './launchResource';
import { StoreDetails } from './native';
const xhrAdapter = require('axios/lib/adapters/xhr.js');
const settleAxiosRequest: (
	resolve: Function,
	reject: Function,
	response: AxiosResponse
) => void = require('axios/lib/core/settle');

export interface LocalApp {
	name: string;
	shortcutPath: string;
	icon: string;
}
export enum LogLevel {
	Error = 'error',
	Warn = 'warn',
	Info = 'info',
}

export const nativeEnvironmentFunctions: Partial<Environment> = {
	ready() {
		callSyncNativeFunction('ready', bridge => bridge.ready());
	},
	writeTraceMessage(event: string, now: number, delta1: number, delta2: number) {
		callSyncNativeFunction('writeTraceMessage', bridge =>
			bridge.writeTraceMessage(event, now, delta1, delta2)
		);
	},
	aotTraceMessage(msg: AotType) {
		callSyncNativeFunction('aotTraceMessage', bridge =>
			bridge.aotTraceMessage(
				JSON.stringify({
					category: msg.category,
					traceMessage: msg.traceMessage,
					logLevel: msg.logLevel,
				})
			)
		);
	},
	workspaceToStoreFrontFallbackFeedbackResponse(
		switchNowClicked: boolean,
		switchToWorkspaceOnNextAppLaunchChecked: boolean
	) {
		callAsyncNativeFunction('workspaceToStoreFrontFallbackFeedbackResponse', bridge =>
			bridge.workspaceToStoreFrontFallbackFeedbackResponse(
				switchNowClicked ? 'yes' : 'no',
				switchToWorkspaceOnNextAppLaunchChecked ? 'yes' : 'no'
			)
		);
	},
	setUserPreferences(preferences: UserPreferences) {
		callSyncNativeFunction('setUserPreferences', bridge =>
			bridge.setUserPreferences(JSON.stringify(preferences))
		);
	},
	openWindow(url: string, _openInTabOnWeb: boolean, options: OpenWindowOptions) {
		return callAsyncNativeFunction<'openWindow', string>(
			'openWindow',
			(bridge, callbackId) =>
				bridge.openWindow(
					url,
					JSON.stringify(options) || JSON.stringify(defaultPopupOptions()),
					callbackId
				)
		);
	},
	focusWindow(id: string) {
		callSyncNativeFunction('focusWindow', bridge => bridge.focusWindow(id));
	},
	closeWindow(id: string) {
		callSyncNativeFunction('closeWindow', bridge => bridge.closeWindow(id));
	},
	isWindowClosed(id: string) {
		return callAsyncNativeFunction<'isWindowClosed', boolean>(
			'isWindowClosed',
			(bridge, callbackId) => bridge.isWindowClosed(id, callbackId),
			(isWindowClosed: string | boolean) => {
				//boolean true/false sent because of bug in RFAndroid build 19.10.5-20.2.0
				if (typeof isWindowClosed === 'boolean') {
					return isWindowClosed;
				}
				return isWindowClosed.toLowerCase() === 'true';
			}
		);
	},
	goOnline() {
		return callAsyncNativeFunction<'goOnline', GoOnlineResult>(
			'goOnline',
			(bridge, callbackId) => bridge.goOnline(this.store.id, callbackId),
			(goOnlineResult: GoOnlineResult) => {
				if (goOnlineResult !== 'success') {
					if (goOnlineResult === 'cloud-offline') {
						environment.setCitrixCloudConnectedStatus(false);
					}
					throw new Error('Failed to go online');
				}
				return goOnlineResult;
			}
		);
	},
	goOnlineSilently() {
		return callAsyncNativeFunction<'goOnlineSilently', GoOnlineResult>(
			'goOnlineSilently',
			(bridge, callbackId) => bridge.goOnlineSilently(this.store.id, callbackId),
			(goOnlineResult: GoOnlineResult) => {
				return goOnlineResult;
			}
		);
	},
	getOnlineStatus() {
		return callAsyncNativeFunction<'getOnlineStatus', OnlineStatus>(
			'getOnlineStatus',
			//First argument as JSON allows flexibility to add options to this call in the future
			(bridge, callbackId) => bridge.getOnlineStatus(JSON.stringify({}), callbackId),
			(getOnlineStatusResult: string) => {
				const parseOnlineStatusResult: GetOnlineStatusResult =
					JSON.parse(getOnlineStatusResult);
				return parseOnlineStatusResult.status;
			}
		);
	},
	ajax(requestConfig) {
		if (shouldBypassNativeAjax(requestConfig)) {
			return xhrAdapter(requestConfig);
		}
		let shouldRetry = true;
		return new Promise((finalResolve, finalReject) => {
			const ajaxCall = (): void => {
				if (!nativeBridgeManager.networkCallsAllowed()) {
					const error = new Error('Citrix Onprem running in offline mode.');
					error.name = 'CitrixOnpremConnectedStatusError';
					finalReject(error);
					return;
				}

				/** In case cloud offline, network calls are blocked at this layer and will throw error.
				 * If we need to make network calls then we need to add X-CLOUD-OFFLINE-REQUEST in header
				 */
				if (
					requestConfig.headers[X_CLOUD_OFFLINE_REQUEST] === undefined &&
					!environment.citrixCloudConnected
				) {
					const error = new Error(
						'Citrix Cloud is not connected. Running in an offline mode.'
					);
					error.name = 'CitrixCloudConnectedStatusError';
					finalReject(error);
					return;
				}

				callNativeAjax(requestConfig).then(async nativeResult => {
					addBreadcrumb({
						category: 'native_xhr',
						data: {
							method: requestConfig.method,
							url: requestConfig.url,
							status_code: nativeResult.status,
						},
						type: 'http',
					});
					if (!nativeResult.status) {
						finalReject(nativeResult);
					}
					if (
						nativeResult.rawHeaders &&
						nativeResult.rawHeaders.toLowerCase().includes(authChallengeHeader)
					) {
						if (shouldRetry) {
							shouldRetry = false;
							if (
								requestConfig.headers[CITRIX_IGNORE_AUTH_CHALLENGE] === undefined &&
								nativeBridgeManager.canGoOnlineFromBridgeAjaxCall()
							) {
								try {
									await this.goOnline();
									ajaxCall();
									return;
								} catch (nativeRejectedResult) {
									finalReject(nativeRejectedResult);
								}
							} else if (this.isGoOnlineSilentlySupported) {
								await this.goOnlineSilently();
								ajaxCall();
								return;
							}
						}
						return finishAjaxRequest(nativeResult, requestConfig)
							.then(finalReject)
							.catch(finalReject);
					}
					return finishAjaxRequest(nativeResult, requestConfig)
						.then(finalResolve)
						.catch(finalReject);
				});
			};
			ajaxCall();
		});
	},
	launchShareFile(params) {
		callAsyncNativeFunction('launchShareFile', (bridge, callbackId) =>
			bridge.launchShareFile(JSON.stringify(params), callbackId)
		);
		trackEvent('NativeAppLaunch - bridge.launchShareFile');
		return true;
	},
	launchMSTeams(url) {
		return callAsyncNativeFunction('launchMSTeams', (bridge, callbackId) =>
			bridge.launchMSTeams(url, callbackId)
		);
	},
	launchViaIcaUrl(resource, config, url, params) {
		return callAsyncNativeFunction(
			'launchViaIcaUrl',
			(bridge, callbackId) => bridge.launchViaIcaUrl(url, callbackId),
			(status: string, contents: string) => ({
				status,
				contents,
			})
		).then(data => {
			if (data.status === 'failure') {
				launchFailed(resource, null);
			} else if (data.status === 'json') {
				handleLaunchStatus(data.contents, config, resource, params);
			}
		});
	},
	launchResource(launchResourceOptions: LaunchResourceOptions) {
		const { resource, preferLeaseLaunch, citrixBrowserResourceID } =
			launchResourceOptions;
		return callAsyncNativeFunction('launchResource', (bridge, callbackId) => {
			bridge.launchResource(
				JSON.stringify({
					resource,
					preferLeaseLaunch,
					citrixBrowserResourceID,
					shareFileData: launchResourceOptions.params?.[0]?.shareFileData,
				}),
				callbackId
			);

			trackEvent('NativeAppLaunch - bridge.launchResource');
		});
	},
	launchApp2(resource, _config, _launchOptions, params) {
		return callAsyncNativeFunction('launchApp2', (bridge, callbackId) => {
			bridge.launchApp2(
				toNativeId(resource, this.store),
				JSON.stringify(params) || '',
				callbackId
			);

			trackEvent('NativeAppLaunch - bridge.launchApp2');
		});
	},
	launchLocalApp(resource, params) {
		return callAsyncNativeFunction('launchLocalApp', (bridge, callbackId) =>
			bridge.launchLocalApp(
				toNativeId(resource, this.store),
				JSON.stringify(params) || '',
				callbackId
			)
		).then((returnCode: string) => {
			return returnCode?.toLowerCase() !== 'success'
				? Promise.reject(returnCode)
				: Promise.resolve();
		});
	},
	launchApp(resource, _config, _launchOptions, _params) {
		return callAsyncNativeFunction('launchApp', (bridge, callbackId) => {
			bridge.launchApp(toNativeId(resource, this.store), callbackId);
			trackEvent('NativeAppLaunch - bridge.launchApp');
		});
	},
	launchCitrixWorkspaceBrowserApp(params) {
		return callAsyncNativeFunction(
			'launchCitrixWorkspaceBrowserApp',
			(bridge, callbackId) => {
				bridge.launchCitrixWorkspaceBrowserApp(JSON.stringify(params), callbackId);
				trackAnalyticsEvent(citrixBrowserAnalyticsReporter.getLaunchEvent());
			}
		).then((_status: string) => {
			return Promise.resolve('success');
		});
	},
	subscribe(resource) {
		return callAsyncNativeFunction(
			'subscribe',
			(bridge, callbackId) =>
				bridge.subscribe(toNativeId(resource, this.store), callbackId),
			(state: subscriptionStatus) => state
		).then(status => {
			return status !== subscriptionStatus.subscribed
				? Promise.reject(new Error('Resource subscription failed for native'))
				: Promise.resolve();
		});
	},
	getLocalApps() {
		return callAsyncNativeFunction(
			'getLocalApps',
			(bridge, callbackId) => bridge.getLocalApps(this.store.id, callbackId),
			(appList: string): LocalApp[] => {
				return JSON.parse(appList);
			}
		);
	},
	getLocalAppWhitelist() {
		return callAsyncNativeFunction(
			'getLocalAppWhitelist',
			(bridge, callbackId) => bridge.getLocalAppWhitelist(this.store.id, callbackId),
			(appWhitelist: string): LocalApp[] => {
				return JSON.parse(appWhitelist);
			}
		);
	},
	installApp(resource) {
		return callAsyncNativeFunction('installApp', (bridge, callbackId) =>
			bridge.installApp(toNativeId(resource, this.store), callbackId)
		);
	},
	configureSiri(resource) {
		return callAsyncNativeFunction<'configureSiri', string>(
			'configureSiri',
			(bridge, callbackId) =>
				bridge.configureSiri(toNativeId(resource, this.store), callbackId),
			(status: string) => {
				return status;
			}
		);
	},
	removeApp(resource) {
		return callAsyncNativeFunction('removeApp', (bridge, callbackId) =>
			bridge.removeApp(toNativeId(resource, this.store), callbackId)
		);
	},
	unsubscribe(resource) {
		return callAsyncNativeFunction(
			'unsubscribe',
			(bridge, callbackId) =>
				bridge.unsubscribe(toNativeId(resource, this.store), callbackId),
			(state: string) => state
		);
	},
	uninstallApp(resource) {
		return callAsyncNativeFunction('uninstallApp', (bridge, callbackId) =>
			bridge.uninstallApp(toNativeId(resource, this.store), callbackId)
		);
	},
	createShortcuts(resources) {
		const ids = resources.map(resource => toNativeId(resource, this.store)).join(';');
		return callAsyncNativeFunction('createShortcuts', (bridge, callbackId) =>
			bridge.createShortcuts(this.store.id, ids, callbackId)
		);
	},
	settingsMenu2(x1, y1, x2, y2, width, height) {
		callAsyncNativeFunction('settingsMenu2', bridge =>
			bridge.settingsMenu2(x1, y1, x2, y2, width, height)
		);
	},
	settingsMenu(x1, y1, x2, y2) {
		callAsyncNativeFunction('settingsMenu', bridge =>
			bridge.settingsMenu(x1, y1, x2, y2)
		);
	},
	showPreferences(x1, y1, x2, y2, width, height) {
		callSyncNativeFunction('showPreferences', bridge =>
			bridge.showPreferences(x1, y1, x2, y2, width, height)
		);
	},
	showAccounts() {
		callAsyncNativeFunction('showAccounts', bridge => bridge.showAccounts());
	},
	switchToRoute(route: string) {
		callAsyncNativeFunction('switchToRoute', bridge => bridge.switchToRoute(route));
	},
	getAccounts() {
		return callSyncNativeFunction('getAccounts', bridge => bridge.getAccounts());
	},
	getAccountsAsync() {
		return callAsyncNativeFunction('getAccountsAsync', (bridge, callbackId) =>
			bridge.getAccountsAsync(callbackId)
		);
	},
	sendAction(id: string) {
		callAsyncNativeFunction('sendAction', bridge => bridge.sendAction(id));
	},
	attemptLogoff(params) {
		const jsonParams = JSON.stringify(params);
		return callAsyncNativeFunction(
			'attemptLogoff',
			(bridge, callbackId) => bridge.attemptLogoff(jsonParams, callbackId),
			(jsonResult: string): AttemptLogoffResult => {
				return safeParse<AttemptLogoffResult>(jsonResult, 'attemptLogoff', {
					// We currently expect any hard errors to handled by CWA, so
					// defaulting to success is acceptable
					result: 'succeeded',
				});
			}
		);
	},
	logOff() {
		callSyncNativeFunction('logOff', bridge => bridge.logOff());
	},
	canLogoff(params) {
		const jsonParams = JSON.stringify(params);
		return callAsyncNativeFunction(
			'canLogoff',
			(bridge, callbackId) => bridge.canLogoff(jsonParams, callbackId),
			(jsonResult: string): CanLogoffResult => {
				return safeParse<CanLogoffResult>(jsonResult, 'canLogoff', {
					result: 'canceled',
				});
			}
		);
	},
	requestLogoff_complete(callbackId, jsonResult) {
		callSyncNativeFunction('requestLogoff_complete', bridge =>
			bridge.requestLogoff_complete(callbackId, JSON.stringify(jsonResult))
		);
	},
	aboutBox(button: Element) {
		const { left, right, top, bottom } = button.getBoundingClientRect();
		callAsyncNativeFunction('aboutBox', bridge =>
			bridge.aboutBox(left, right, top, bottom)
		);
	},
	getInstalledApps3(_resources) {
		return callAsyncNativeFunction(
			'getInstalledApps3',
			(bridge, callbackId) => bridge.getInstalledApps3(this.store.id, callbackId),
			(
				appList: string,
				onlyInstalledAppsAreSubscribed: boolean
			): InstalledResourceData => {
				const parsedAppList: InstalledResource[] = !!appList && JSON.parse(appList);
				return {
					resources: parsedAppList || [],
					onlyInstalledAppsAreSubscribed,
				};
			}
		);
	},
	getSiriRegisteredApps() {
		return callAsyncNativeFunction(
			'getSiriRegisteredApps',
			(bridge, callbackId) => bridge.getSiriRegisteredApps(this.store.id, callbackId),
			(appList: string): SiriRegisteredResourceData => {
				const parsedAppList: SiriRegisteredResource[] = !!appList && JSON.parse(appList);
				return {
					resources: parsedAppList || [],
				};
			}
		);
	},
	fetchActivityList() {
		return callAsyncNativeFunction(
			'fetchActivityList',
			(bridge, callbackId) => bridge.fetchActivityList(callbackId),
			(resourcesList: string): ActivityCardResourceList => {
				let parsedAppList: ActivityCardResourceList;
				if (!!resourcesList) {
					try {
						parsedAppList = JSON.parse(resourcesList);
					} catch (error) {
						logError(error, {
							customMessage:
								'Error during parsing the response from fetchActivityList in nativeFunctions',
						});
					}
				}
				return {
					resources: parsedAppList?.resources || [],
				};
			}
		);
	},
	getInstalledApps2(resources) {
		const subscribedResources = resources.filter(
			r => r.subscriptionstatus === subscriptionStatus.subscribed || r.mandatory
		);
		const ids = subscribedResources.map(r => toNativeId(r, this.store)).join(';');
		return callAsyncNativeFunction(
			'getInstalledApps2',
			(bridge, callbackId) => bridge.getInstalledApps2(this.store.id, ids, callbackId),
			(
				changes: any,
				appList: string,
				onlyInstalledAppsAreSubscribed: boolean
			): InstalledResourceData => {
				if (String(changes).toLowerCase() === 'true') {
					return {
						resources: appList.split(';').map(resource => ({ appId: toWebId(resource) })),
						onlyInstalledAppsAreSubscribed,
					};
				}
				return {};
			}
		);
	},
	getInstalledApps(_resources) {
		return callAsyncNativeFunction(
			'getInstalledApps',
			(bridge, callbackId) => bridge.getInstalledApps(this.store.id, callbackId),
			(
				appList: string,
				onlyInstalledAppsAreSubscribed: boolean
			): InstalledResourceData => ({
				resources: appList.split(';').map(resource => ({ appId: toWebId(resource) })),
				onlyInstalledAppsAreSubscribed,
			})
		);
	},
	noteRefreshStart() {
		return callSyncNativeFunction('noteRefreshStart', bridge =>
			bridge.noteRefreshStart()
		);
	},
	noteRefreshDone(succeeded: number, failed: number) {
		return callSyncNativeFunction('noteRefreshDone', bridge =>
			bridge.noteRefreshDone(succeeded, failed)
		);
	},
	refreshDone(succeeded: number, failed: number) {
		return callAsyncNativeFunction('refreshDone', (bridge, callbackId) =>
			bridge.refreshDone(succeeded, failed, callbackId)
		);
	},
	sync_refreshDone(succeeded: number, failed: number) {
		return callSyncNativeFunction('sync_refreshDone', bridge =>
			bridge.sync_refreshDone(succeeded, failed)
		);
	},
	getFileUri(id: string) {
		return callAsyncNativeFunction('getFileUri', (bridge, callbackId) =>
			bridge.getFileUri(id, callbackId)
		);
	},
	sync_getFileUri(id: string) {
		return callSyncNativeFunction('sync_getFileUri', bridge =>
			bridge.sync_getFileUri(id)
		);
	},
	dispatchEventToNative<T>(event: Event<T>): void {
		if (
			environment.nativePlatform === NativePlatform.Windows &&
			event.type !== FeedCardEventType.FEED_CARD_ACTION_EXECUTED
		) {
			return;
		}
		return callSyncNativeFunction('dispatchEventToNative', bridge => {
			bridge.dispatchEventToNative(JSON.stringify(event));
		});
	},
	dispatchEventToNative2<T>(event: Event<T>): void {
		return callSyncNativeFunction('dispatchEventToNative2', bridge => {
			bridge.dispatchEventToNative2(JSON.stringify(event));
		});
	},
	setAADResponse(authResultJson: string) {
		return callSyncNativeFunction('setAADResponse', bridge => {
			bridge.setAADResponse(authResultJson);
		});
	},
	setAADError(error: string): void {
		return callSyncNativeFunction('setAADError', bridge => {
			bridge.setAADError(error);
		});
	},
	performAuthorization(jsonPayload): Promise<MSALAuthorizationResultType> {
		const performAuthorizationV1 = 'performAuthorization';
		const performAuthorizationV2 = 'performAuthorizationV2';
		const performAuthorization = environment.nativeCapabilities.functions.includes(
			performAuthorizationV2
		)
			? performAuthorizationV2
			: performAuthorizationV1;
		return callAsyncNativeFunction(
			performAuthorization,
			(bridge, callbackId) => bridge[performAuthorization](jsonPayload, callbackId),
			json => JSON.parse(json) as MSALAuthorizationResultType
		);
	},
	setDaasUIMetadata(metadata: DaasUIMetadata): void {
		return callSyncNativeFunction('setDaasUIMetadata', bridge => {
			bridge.setDaasUIMetadata(JSON.stringify(metadata));
		});
	},
	sync_setItem(key: string, value: string) {
		callSyncNativeFunction('sync_setItem', bridge => bridge.sync_setItem(key, value));
	},
	setItem(key: string, value: string) {
		return callAsyncNativeFunction('setItem', (bridge, callbackId) =>
			bridge.setItem(key, value, callbackId)
		);
	},

	sync_getItem(key: string): string {
		return callSyncNativeFunction('sync_getItem', bridge => bridge.sync_getItem(key));
	},
	getItem(key: string) {
		return callAsyncNativeFunction('getItem', (bridge, callbackId) =>
			bridge.getItem(key, callbackId)
		);
	},
};
function safeParse<T>(
	json: string,
	methodName: keyof typeof nativeEnvironmentFunctions,
	fallback: T
): T {
	try {
		return JSON.parse(json) as T;
	} catch (err) {
		logError(err, {
			customMessage: `Expected JSON content result for "${methodName}"`,
			additionalContext: {
				received: json,
			},
		});

		return fallback;
	}
}

function toWebId(nativeId: string) {
	const i = nativeId.indexOf('@@');
	if (i !== -1) {
		nativeId = nativeId.substring(i + 2);
	}

	return nativeId;
}

function toNativeId(resource: Resource, store: StoreDetails) {
	if (store.id) {
		return `${store.id}@@${resource.id}`;
	}

	return resource.id;
}

export interface LaunchCitrixWorkspaceBrowserAppParams {
	resourceID: string;
}

export interface LaunchShareFileParams {
	command: string;
	itemUrl?: string;
	linkUrl?: string;
}

interface AjaxResult {
	data: string;
	status: number;
	statusText: string;
	rawHeaders: string;
}

function shouldBypassNativeAjax(requestConfig: AxiosRequestConfig) {
	if (requestConfig.data instanceof FormData) {
		return true;
	}

	if (
		location.protocol === 'file:' ||
		requestConfig.url.startsWith(environment.store.baseUri) ||
		requestConfig.baseURL?.startsWith(environment.store.baseUri) ||
		requestConfig.requireNativeXHR
	) {
		return false;
	}

	return true;
}

function callNativeAjax(requestConfig: AxiosRequestConfig) {
	const serializedHeaders = Object.keys(requestConfig.headers)
		.filter(key => !!requestConfig.headers[key])
		.map(key => `${key}:${requestConfig.headers[key]}`)
		.join('\n');

	return callAsyncNativeFunction(
		'ajax',
		(bridge, callbackId) =>
			bridge.ajax(
				requestConfig.method.toUpperCase(),
				requestConfig.url,
				serializedHeaders,
				requestConfig.data || '',
				requestConfig.downloadId || '',
				callbackId
			),
		(
			statusText: string,
			status: number,
			data: string,
			rawHeaders: string
		): AjaxResult => ({
			data,
			status,
			statusText,
			rawHeaders,
		})
	);
}

function finishAjaxRequest(result: AjaxResult, config: AxiosRequestConfig) {
	return new Promise((resolve, reject) => {
		const headers = {};
		if (result.rawHeaders) {
			result.rawHeaders.split('\n').forEach(line => {
				const split = line.indexOf(':');
				const key = line.substr(0, split).toLowerCase();
				const value = line.substr(split + 1);
				headers[key] = value;
			});
		}

		settleAxiosRequest(resolve, reject, {
			config,
			data: result.data,
			status: result.status,
			statusText: result.statusText,
			headers,
		});
	});
}
