import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { hasUIProperty, UIProperty } from 'Environment/hasUIProperty';
import { ResourceContext } from 'Workspace/ResourceProvider/Context';
import { UserContext } from 'Workspace/UserContext';

export function isDesktopOnlyHomepage(
	workspaceConfiguration: WorkspaceConfiguration,
	iwsEnabled: boolean,
	resourceContext: ResourceContext
): boolean | null {
	if (resourceContext.resources === null) {
		// homepage undetermined because the resources are still loading
		return null;
	}

	if (
		iwsEnabled ||
		hasUIProperty(workspaceConfiguration, UIProperty.Files) ||
		!hasUIProperty(workspaceConfiguration, UIProperty.Desktops)
	) {
		return false;
	}

	return (
		!!resourceContext.resources.length &&
		resourceContext.resources.every(resource => resource.isdesktop)
	);
}

export function isDesktopOnlyHomepageInNonDaasVision(
	workspaceConfiguration: WorkspaceConfiguration,
	userContext: UserContext,
	resourceContext: ResourceContext
): boolean {
	return (
		!userContext.isDaasVisionEnabled &&
		isDesktopOnlyHomepage(
			workspaceConfiguration,
			userContext.isIWSEnabled,
			resourceContext
		)
	);
}
