import React from 'react';
import { t } from '@citrite/translate';
import {
	NotificationBadgeIcon,
	palette,
	SustainableLeafIcon,
	typography,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import { useFormFactor } from 'App/Header/useFormFactor';
import { getResourceSubText } from 'App/Screen/util';
interface PowerStateIndicatorProps {
	resourceType: string;
	powerState: string;
}

const StyledLightText = styled.div<{ isSmallFormFactor: boolean }>`
	color: ${palette.black};
	padding: 6px 0;
	font-weight: ${typography.weights.regular};
	line-height: 11px;
	font-size: ${typography.sizes.xsml2};
	min-width: fit-content;
	display: flex;
	align-items: center;
	${({ isSmallFormFactor }) => (isSmallFormFactor ? 'gap: 4px;' : 'gap: 8px;')}
`;

const StyledSpanText = styled.span`
	opacity: 0.6;
`;

export const PowerStateIndicator: React.FC<PowerStateIndicatorProps> = ({
	resourceType,
	powerState,
}) => {
	const { isSmallFormFactor } = useFormFactor();
	switch (powerState) {
		case PowerState.ON:
			return (
				<StyledLightText isSmallFormFactor={isSmallFormFactor}>
					<NotificationBadgeIcon color={palette.green600} size={12} />
					<StyledSpanText>{t('Workspace:activity_manager.powered_on')}</StyledSpanText>
				</StyledLightText>
			);
		case PowerState.OFF:
			return (
				<StyledLightText isSmallFormFactor={isSmallFormFactor}>
					<NotificationBadgeIcon color={palette.grey600} size={12} />
					<StyledSpanText>{t('Workspace:activity_manager.powered_off')}</StyledSpanText>
				</StyledLightText>
			);
		case PowerState.SUSPENDED:
			return (
				<StyledLightText isSmallFormFactor={isSmallFormFactor}>
					<SustainableLeafIcon color={palette.green900} size={12} />
					<StyledSpanText>
						{t('Workspace:activity_manager.powerestate_in_Hibernation')}
					</StyledSpanText>
				</StyledLightText>
			);
		default:
			return (
				<StyledLightText isSmallFormFactor={isSmallFormFactor}>
					<StyledSpanText>{getResourceSubText(resourceType)}</StyledSpanText>
				</StyledLightText>
			);
	}
};
