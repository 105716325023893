import { post } from '@citrite/http';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import { getTimestamp } from 'App/Activity/Utility';
import { getFromLocalStorage, setInLocalStorage } from 'javascript/sf/Storage';
export interface MachineListRequestData {
	resourceIds: string[];
}

export interface MachineListResponseData {
	machines: (Machine | MachineError)[];
}

const MACHINE_LIST_CACHE_KEY = 'machineList';
export async function fetchMachineList(
	endpoint: string,
	resourceIds: string[]
): Promise<MachineListResponseData> {
	const validatedMachineList: MachineListResponseData = {
		machines: [],
	};

	const machineListFromNetwork = await post<MachineListResponseData>(endpoint, {
		resourceIds,
	});
	const machineListFromCache: MachineListResponseData = getFromLocalStorage(
		MACHINE_LIST_CACHE_KEY
	) || { machines: [] };

	machineListFromNetwork.machines.forEach(networkMachine => {
		const cachedMachine = machineListFromCache.machines?.find(
			({ resourceId }) => resourceId === networkMachine.resourceId
		);

		const cachedMachineLastUpdatedTime = getTimestamp(
			cachedMachine?.machineData?.lastUpdatedTime
		);
		const networkMachineLastUpdatedTime = getTimestamp(
			networkMachine?.machineData?.lastUpdatedTime
		);
		if (cachedMachineLastUpdatedTime > networkMachineLastUpdatedTime) {
			validatedMachineList.machines.push(cachedMachine);
		} else {
			validatedMachineList.machines.push(networkMachine);
		}
	});

	setInLocalStorage(MACHINE_LIST_CACHE_KEY, validatedMachineList);

	return validatedMachineList;
}
