import * as React from 'react';
import { useTheme } from '@emotion/react';
import { logAdminNotificationError } from 'AdminNotificationService';
import { useAdminNotification } from 'AdminNotificationService/AdminNotificationContext';
import {
	AdminNotification,
	BannerPosition,
} from 'AdminNotificationService/createAdminNotificationClient';
import { useFormFactor } from 'App/Header/useFormFactor';
import { DaasNotificationBanner } from 'Components/AppLayout/DaasNotificationBanner';
import { MastheadOffset } from 'Workspace/ThemeProvider';
import { UserContext, useUserContext } from 'Workspace/UserContext';
import {
	StyledBannerText,
	StyledBannerTitle,
	StyledInlinedBanner,
} from './AdminNotificationBanner.styled';
import {
	createNotificationMessage,
	getBannerPositionFromFormFactor,
	shouldDisplayBanner,
} from './AdminNotificationBannerUtils';

interface State {
	title: string;
	sanitizedMessage: string;
	bannerPosition: BannerPosition;
}

export interface AdminBannerProps {
	intendedPosition?: BannerPosition;
}

interface Props {
	isLargeFormFactor: boolean;
	mastheadOffset: MastheadOffset;
	intendedPosition?: BannerPosition;
	userContext: UserContext;
	adminNotification: AdminNotification;
}

export interface ContentProps {
	isExpanded?: boolean;
}

class _AdminNotificationBanner extends React.Component<Props, State> {
	public state: State = {
		title: null,
		sanitizedMessage: null,
		bannerPosition: BannerPosition.Top,
	};

	public async componentDidMount() {
		const { adminNotification } = this.props;
		if (adminNotification) {
			await this.setAdminNotificationState(adminNotification);
		}
	}

	public async componentDidUpdate(prevProps: Readonly<Props>) {
		const { adminNotification } = this.props;
		if (adminNotification && adminNotification !== prevProps.adminNotification) {
			await this.setAdminNotificationState(adminNotification);
		}
	}

	private async setAdminNotificationState(adminNotification: AdminNotification) {
		try {
			const sanitizedNotificationMessage = await createNotificationMessage(
				adminNotification,
				this.props.userContext?.isDaasVisionEnabled
			);
			this.setState({
				title: adminNotification.name,
				sanitizedMessage: sanitizedNotificationMessage,
				bannerPosition: adminNotification.position,
			});
		} catch (e) {
			logAdminNotificationError(e);
		}
	}

	private getDaasBannerContent = () => (props: ContentProps) =>
		(
			<StyledInlinedBanner showSingleLine={false}>
				<StyledBannerTitle
					data-testid="daas-admin-banner-title"
					isExpanded={props.isExpanded}
				>
					<b>{this.state.title}</b>
				</StyledBannerTitle>

				{props.isExpanded && (
					<StyledBannerText
						dangerouslySetInnerHTML={{
							__html: this.state.sanitizedMessage,
						}}
						padding={0}
					/>
				)}
			</StyledInlinedBanner>
		);

	public render() {
		if (!(this.state.title && this.state.sanitizedMessage)) {
			return null;
		}
		if (this.props.userContext?.isDaasVisionEnabled) {
			if (
				!shouldDisplayBanner(
					this.props.intendedPosition,
					this.state.bannerPosition,
					this.props.isLargeFormFactor
				)
			) {
				return null;
			}
		}
		const position = getBannerPositionFromFormFactor(
			this.state.bannerPosition,
			this.props.isLargeFormFactor
		);

		return (
			<DaasNotificationBanner
				Content={this.getDaasBannerContent()}
				bannerPosition={position}
				mastheadOffset={this.props.mastheadOffset}
				isAdminBanner
			/>
		);
	}
}

export function AdminNotificationBanner(props: AdminBannerProps) {
	const { adminNotification } = useAdminNotification();
	const contexts: Props = {
		isLargeFormFactor: useFormFactor().isLargeFormFactor,
		mastheadOffset: useTheme().mastheadOffset,
		intendedPosition: props.intendedPosition,
		userContext: useUserContext(),
		adminNotification,
	};
	return <_AdminNotificationBanner {...contexts} />;
}
