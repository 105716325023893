import React, { useEffect, useState } from 'react';
import { CustomizeUserHomeModal } from 'Components/FTU/CustomizeUserHomeModal';
import { SignInBlockingModal } from 'Components/Modals/SignInBlockingModal';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { setInLocalStorage } from 'javascript/sf/Storage';
import {
	BlockingNotification,
	BlockingNotificationStored,
	getBlockingNotificationDataAndModalAction,
	getHashOfBlockingNotification,
	isBlockingNotificationEnabledByConfig,
	isFTUEnabledByConfig,
	ModalAction,
} from './SignInBlockingModalUtils';

export interface Props {
	userId: string;
}

export const CustomBlockingNotification: React.FC<Props> = ({ userId }) => {
	const [isBlockingModalVisible, setBlockingModalVisible] = useState(false);
	const { workspaceConfiguration } = useConfigurationContext();
	const [blockingNotification, setBlockingNotification] =
		useState<BlockingNotification>(null);
	const [canShowFTU, setCanShowFTU] = useState(false);

	const storageKey = 'SignInBlockingNotification#' + userId;

	const shouldShowFTU = () =>
		isFTUEnabledByConfig(workspaceConfiguration, userId) && canShowFTU;

	useEffect(() => {
		let isMounted = true;
		if (userId) {
			if (isBlockingNotificationEnabledByConfig(workspaceConfiguration)) {
				getBlockingNotificationDataAndModalAction(
					workspaceConfiguration?.storeProxy?.notifications?.blockingNotificationURL,
					storageKey
				).then(({ notification, modalAction }) => {
					if (isMounted) {
						setBlockingNotification(notification);

						if (modalAction === ModalAction.Show) {
							setBlockingModalVisible(true);
						} else {
							setCanShowFTU(true);
						}
					}
				});
			} else {
				setCanShowFTU(true);
			}
		}

		return () => {
			setBlockingModalVisible(false);
			setBlockingNotification(null);
			isMounted = false;
			setCanShowFTU(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId]);

	function onClose() {
		getHashOfBlockingNotification(blockingNotification).then((hashHex: string) => {
			const blockingNotificationStored: BlockingNotificationStored = {
				hash: hashHex,
				utcDateLastShown: new Date().getTime(),
			};
			setInLocalStorage(storageKey, blockingNotificationStored);
		});
		setBlockingModalVisible(false);
		setCanShowFTU(true);
	}

	if (!isBlockingModalVisible) {
		return <>{shouldShowFTU() && <CustomizeUserHomeModal />}</>;
	}

	return (
		<SignInBlockingModal
			handleClose={onClose}
			enabled={isBlockingModalVisible}
			blockingNotification={blockingNotification}
		/>
	);
};
