import {
	PlatformDependencies,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { trackAnalyticsEvent } from 'analytics';
import { logError } from 'remoteLogging';
import { GlobalAppConfigResponse } from 'GlobalAppConfigService/types';
import { ErrorLevel } from 'Loggers/LoggingProvider';
import { container } from 'Workspace/DependencyManagement';
import { GlobalAppConfigV2Payload } from 'Workspace/TelemetryEvents/globalAppConfigV2/createGlobalAppConfigCASReporter';

export abstract class GacsTicketFetcherBase {
	protected GACS_TOKEN_ENDPOINT_SERVICE = 'TokenService';
	protected MAX_GACS_TIMEOUT_MS = 5000;
	protected GACS_TIMEOUT_ERROR = new Error('GACS_TIMEOUT');

	public abstract isGacsV2Enabled(workspaceConfig: WorkspaceConfiguration): boolean;

	protected abstract fetchGacsTicketFromNetwork(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse>;

	public async getGacsParams(): Promise<string> {
		const workspaceConfig = container
			.resolve(PlatformDependencies.WorkspaceConfiguration)
			.get();

		if (!this.isGacsV2Enabled(workspaceConfig)) {
			return null;
		}

		const gacsTicket = await this.getGacsTicket(workspaceConfig);
		if (!gacsTicket?.access || !gacsTicket?.metadata) {
			return null;
		}
		trackAnalyticsEvent(GlobalAppConfigV2Payload.isRequestSuccessful(true));
		return `&gacsToken=${gacsTicket.access}&gacsMeta=${gacsTicket.metadata}`;
	}

	private async getGacsTicket(
		workspaceConfiguration: WorkspaceConfiguration
	): Promise<GlobalAppConfigResponse> {
		try {
			const startTime = Date.now();
			//We will return a in-memory cached Ticket in a later PR from here to avoid extra network calls.

			// Return the result of the network fetch or null if it takes longer than max timeout
			const timeoutPromise = new Promise<GlobalAppConfigResponse>((_, reject) => {
				setTimeout(() => {
					reject(this.GACS_TIMEOUT_ERROR);
				}, this.MAX_GACS_TIMEOUT_MS);
			});

			const gacsTicket = await Promise.race([
				timeoutPromise,
				this.fetchGacsTicketFromNetwork(workspaceConfiguration),
			]);

			const endTime = Date.now();

			const latency = endTime - startTime;
			trackAnalyticsEvent(GlobalAppConfigV2Payload.getLatency(latency));
			return gacsTicket;
		} catch (error) {
			if (error === this.GACS_TIMEOUT_ERROR) {
				trackAnalyticsEvent(GlobalAppConfigV2Payload.isTimedOut(true));
			} else {
				trackAnalyticsEvent(GlobalAppConfigV2Payload.isRequestSuccessful(false));
				logError(error, { additionalContext: { level: ErrorLevel.CRITICAL } });
			}
			return null;
		}
	}
}
