import {
	getFromLocalStorage,
	removeFromLocalStorage,
	setInLocalStorage,
} from 'javascript/sf/Storage';

const clientDetectionKey = 'clientDetectionKey';
const previousClientDetectionKey = 'previousClientDetectionKey';
const clientVersionKey = 'clientVersionKey';
const saasLaunchSupportKey = 'saasLaunchSupportKey';

export enum clientType {
	html5 = 'html5',
	native = 'native',
	browserextension = 'browserextension',
	chromeapp = 'chromeapp',
}

export function getClientType() {
	return getFromLocalStorage<clientType>(clientDetectionKey);
}

export function setClientType(
	type: clientType,
	version?: string,
	supportsSaasLaunch?: string
) {
	setInLocalStorage(clientDetectionKey, type);
	if (version) {
		setInLocalStorage(clientVersionKey, version);
	} else {
		removeFromLocalStorage(clientVersionKey);
	}
	if (supportsSaasLaunch) {
		setInLocalStorage(saasLaunchSupportKey, supportsSaasLaunch);
	} else {
		removeFromLocalStorage(saasLaunchSupportKey);
	}
}

export function setPreviousClientType(type: clientType) {
	setInLocalStorage(previousClientDetectionKey, type);
}

export function getSaasLaunchCapability() {
	return getFromLocalStorage<string>(saasLaunchSupportKey);
}

export function getClientVersion() {
	// https://issues.citrite.net/browse/WSUI-10235
	// Linux Native client builds are sending version as intetger 9909 which messes the code
	// which tries parse the client version strings. So, we are converting version to string.
	// Also ensure we maintain the null\undefined semantics for version.
	const clientVersion = getFromLocalStorage<string>(clientVersionKey);
	return clientVersion ? clientVersion.toString() : clientVersion;
}

export function getPreviousClientType() {
	return getFromLocalStorage<clientType>(previousClientDetectionKey);
}
