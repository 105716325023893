import { ActivityDesktopSolidIcon, AppsIcon } from '@citrite/web-ui-component';
import { ResourceType as ResourceType2 } from 'App/Tile/ResourceDetails';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const getDefaultSessionIcon = (resource: Resource) => {
	return resource?.type === ResourceType2.Desktop ? ActivityDesktopSolidIcon : AppsIcon;
};

export const getTimestamp = (isoTime: string) => {
	let timestamp = new Date(isoTime).getTime();
	if (isNaN(timestamp)) {
		timestamp = 0;
	}
	return timestamp;
};

export const isStaleMachineData = (lastUpdatedTime: string) => {
	const beforeFifteenMinsFromNow = Date.now() - 15 * 60 * 1000;
	return getTimestamp(lastUpdatedTime) < beforeFifteenMinsFromNow;
};
