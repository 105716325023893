import React from 'react';
import { layout } from '@citrite/web-ui-component';
import { AdaptiveDropdown, ContextMenuItem } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { useResourceManagerContext } from 'App/Activity/ResourceManagerContext';
import { useFormFactor } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const StyledDiv = styled.div<{
	isSmallFormFactor: boolean;
}>`
	position: absolute;
	top: ${layout.smallSpace};
	right: ${layout.smallSpace};
	text-align: left;
	${props =>
		props.isSmallFormFactor &&
		`
		position: relative;
		width: 20%;
		top: 0;
		right: 0;
		text-align: end;
	`}
`;

interface Props {
	resource: Resource;
	contextMenuBuilder: (resource: Resource) => ContextMenuItem[];
}

export const ResourceMenu = ({ resource, contextMenuBuilder }: Props) => {
	const { isSmallFormFactor } = useFormFactor();
	const { refresh } = useResourceManagerContext();
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);

	const onClick = (event: React.MouseEvent<HTMLElement>) => {
		//TO FIX: Small screens have multiple refresh calls
		if (isMachinePowerStateEnabled) {
			resource.canQueryMachineState && refresh();
		}
		event.stopPropagation();
	};

	return (
		<StyledDiv
			onClick={onClick}
			data-testid={`${resource.id}-more-action`}
			data-analytics-name="tile-more-action"
			isSmallFormFactor={isSmallFormFactor}
		>
			<AdaptiveDropdown
				name={resource.name}
				data={resource}
				showMobileActionSheet={isSmallFormFactor}
				contextMenuBuilder={contextMenuBuilder}
				placement="bottom-start"
			/>
		</StyledDiv>
	);
};
