import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	breakpoints,
	fromThemeColors,
	layout,
	MainRegion,
	palette,
} from '@citrite/web-ui-component';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { isSmallForm } from 'App/Header/useFormFactor';
import { FeatureFlag } from 'Environment/FeatureFlag';

const bottomMargin = isSmallForm() ? bottomNavBarHeight : '0px';
const maxViewWidth = '1920px';
const citrixBrandingHeight = '60px';
const containerPaddingHeight = `(2 * ${layout.mediumLargeSpace})`;

export const StyledApplication = styled.div<{
	isOffWhiteBackground?: boolean;
}>`
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-color: ${props =>
		props.isOffWhiteBackground
			? palette.grey100
			: getFeatureFlagValue(FeatureFlag.SimplifiedNav)
			? fromThemeColors('primaryAppBackground')(props)
			: fromThemeColors('primaryContainerBackground')(props)};
`;

export const StyledAppBody = styled.div<{
	clientHeight: string;
	offsetTop: number;
	isSmallFormFactor: boolean;
}>`
	align-items: flex-start;
	display: flex;
	flex-grow: 1;
	min-width: 200px;
	background-color: ${props =>
		props.isSmallFormFactor && fromThemeColors('primaryContainerBackground')(props)};
	${() =>
		getFeatureFlagValue(FeatureFlag.SimplifiedNav) && {
			maxWidth: maxViewWidth,
			// IE11 needs a width to make sense of centered content
			width: '100vw',
		}};
	${breakpoints.onMobile} {
		/*
			Allowing this content to scroll on iOS hides the
			hamburger menu for the left nav
		*/
		overflow-y: initial;
		${props =>
			`height: calc(${props.clientHeight} - ${props.offsetTop}px - ${bottomMargin} - 
				${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	}
`;

export const StyledAppContent = styled(MainRegion, { shouldForwardProp: isPropValid })<{
	clientHeight: string;
}>`
	background-color: inherit;
	flex-grow: 1;
	max-width: 100%;
	/* 
	Set overflow and fixed height for IE11 so that it will 
	scroll this content area while allowing for the left 
	nav to stick in place. This causes the nav to remain 
	alongside the content, even when it's centered
	in an ultrawide view.
	*/
	overflow-y: auto;
	${props =>
		`height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffset}px - ${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	/* 
	Set back to default overflow and min-height for 
	sticky-supporting clients.
	Scrolling will be handled by StyledAppBody, which
	allows the scrollbar to be rendered out to the side 
	of the viewport to make it look better at ultra-wide.
	*/
	@supports (position: sticky) {
		height: 100%;
		overflow-y: visible;
		${props =>
			`min-height: calc(${props.clientHeight} - ${props.theme.mastheadOffset.mastheadOffset}px - ${props.theme.mastheadOffset.bottomBannerOffset}px)`};
	}
	${breakpoints.onMobile} {
		overflow-x: hidden;
		width: 100%;
	}
`;

export const StyledSecondaryContent = styled.div<{
	clientHeight: string;
}>`
	padding: 20px;
	box-sizing: border-box;
	width: inherit;
	background: ${fromThemeColors('secondaryAppBackground')};
	${
		({ clientHeight, theme }) =>
			`min-height: calc(
                ${clientHeight} - ${theme.mastheadOffset.mastheadOffset}px -
                ${theme.mastheadOffset.bottomBannerOffset}px - ${containerPaddingHeight} - ${citrixBrandingHeight} - ${layout.mediumLargeSpace} - ${layout.mediumLargeSpace});`
		/**
		 * fixes the overflow bug in the settings screen
		 * and accomodates the Citrix Branding component for the DaasVision UI
		 * while not messing with the CSS of the Old UI
		 */
	};
`;
