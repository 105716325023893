import * as React from 'react';
import { ClientInstance, postAsFormData } from '@citrite/http';
import { MSALAuthorizationResultType } from '@citrite/msal-browser';
import { Writable } from '@citrite/utility-types';
import { ShowModal } from '@citrite/web-ui-component';
import {
	NativeAccounts,
	NativePlatform,
	UserPreferences,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { addBreadcrumb, setLoggingTags } from 'remoteLogging';
import { AotType } from 'App/AotTrace/AotLogger';
import { DaasUIMetadata } from 'App/Screen';
import { AboutWorkspace } from 'Components/About';
import { isMobileApp } from 'Environment/deviceSupportsResource';
import { downloadICAFile } from 'Environment/launchResource/downloadICAFile';
import { LaunchOptions, LaunchStatus } from 'Environment/launchResource/launchResource';
import { initMsalSdk } from 'Environment/msal/nativeMsalAuth';
import { ProvidedNativeFunctions } from 'Environment/providedNativeFunctions';
import { getUrlParameter } from 'Environment/setup/getUrlParameter';
import { detectNativePlatform } from 'Environment/setup/nativeDetection';
import { installToolFunction } from 'javascript/interactiveTools';
import { setInSessionStorage } from 'javascript/sf/Storage';
import { isGatewaySession } from 'Login/DSAuth/authentication';
import { monitoring, MonitoringData } from 'MonitoringAnalytics';
import { createForBrowser as createSSOProxyClient } from 'ssoProxyClient';
import { Event } from 'Workspace/EventBus';
import {
	ActivityCardResourceList,
	InstalledResourceData,
	Resource,
	SiriRegisteredResourceData,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';
import { configureAndroid } from './android';
import { supportsIcaLaunch } from './IcaLaunch';
import { configureIos } from './ios';
import { handleLaunchStatus } from './launchResource';
import { resourceLaunch } from './launchResource/resourceLaunch';
import {
	buildNativeEnvironment,
	MenuPreferences,
	NativeCapabilities,
	StoreDetails,
} from './native';
import {
	LaunchCitrixWorkspaceBrowserAppParams,
	LaunchShareFileParams,
	LocalApp,
} from './nativeFunctions';
import { configureSimulatedNativeEnvironment } from './simulated';
import {
	defaultPopupOptions,
	formatPopupOptions,
	getBrowserWindowById,
	OpenWindowOptions,
	trackBrowserWindow,
	untrackBrowserWindowById,
} from './window/BrowserWindow';
import { configureWindows } from './windows';

const xhrAdapter = require('axios/lib/adapters/xhr.js');

export interface LaunchProgressHandler {
	validateLaunchNextStep?: (status: LaunchStatus) => boolean;
	launchCancellationToken?: string;
}

export interface LaunchResourceOptions {
	resource: Resource;
	workspaceConfiguration: WorkspaceConfiguration;
	launchOptions?: LaunchOptions;
	params?: any;
	targetWindow?: Window;
	preferLeaseLaunch: boolean;
	/**
	 * A generated id passed as Citrix-TransactionId used to track all requests related to a cvad launch
	 */
	operationId?: string;
	launchProgressHandler?: LaunchProgressHandler;
	citrixBrowserResourceID?: string;
}

const nativeMessagingType: 'url' = getUrlParameter('notify');
const nativeCommunicationMethod = nativeMessagingType ? 'messaging' : 'bridge';
export const originalHash = location.hash;
export const authChallengeHeader = 'citrixwebreceiver-authenticate';
const citrixApplicationHeaderKey = 'x-citrix-application';
const citrixApplicationHeader = 'Receiver for Web';
export const citrixServerNameHeaderKey = 'x-citrix-server-name';
const citrixTransactionIdHeaderKey = 'Citrix-TransactionId';

function getDefaultBaseUri() {
	if (!location.pathname.includes('/assets/workspace/')) {
		return '';
	}

	const indexOfAssets = location.pathname.indexOf('/assets/workspace/');
	const storeUrl = location.pathname.substring(0, indexOfAssets);

	return `https://${location.hostname}${storeUrl}/`;
}

export type GoOnlineResult = 'success' | 'failure' | 'cancelled' | 'cloud-offline';
export interface GetOnlineStatusResult {
	status: OnlineStatus;
}
export enum OnlineStatus {
	Online = 'online',
	Offline = 'cloud-offline',
}
type LaunchLocalAppParam = {
	path: string;
};

type AttemptLogoffParams = { reason: 'userLogoff' | 'passwordReset' };
export type AttemptLogoffResult = { result: 'succeeded' | 'canceled' };
type RequestLogoffResult = { result: 'succeeded' | 'failed' };
type CanLogoffParams = { reason: 'userLogoff' };
type RecentItemsCategory = 'apps' | 'desktops' | 'actions' | 'files';
export type SupportedRouteIds = 'allApps' | 'allDesktops' | 'allActions';

export interface RecentItems {
	category: RecentItemsCategory;
	id: string;
	name: string;
	iconUrl?: string;
	url?: string;
	provider?: string;
}

export interface Action {
	id: string;
	name: string;
	iconUrl: string;
	serviceName: string;
}

export type CanLogoffResult = { result: 'canceled' | 'proceed' };

let loginExpired: () => void;
export function setLoginExpiredCallback(callback: () => void) {
	loginExpired = callback;
}

export function isGatewaySessionExpired(result: AxiosResponse): boolean {
	const citrixApplicationHeaderResponse: string | undefined =
		result.headers?.[citrixApplicationHeaderKey];
	if (IS_ON_PREM && result.status === 200 && result.headers && isGatewaySession()) {
		if (
			citrixApplicationHeaderResponse === undefined ||
			citrixApplicationHeaderResponse.toLowerCase() !==
				citrixApplicationHeader.toLowerCase()
		) {
			return true;
		}
	}
	return false;
}

export function isAuthChallengePresent(
	result: AxiosResponse,
	config: AxiosRequestConfig
): boolean {
	return (
		result.headers &&
		Object.keys(result.headers).some(k => k.toLowerCase() === authChallengeHeader) &&
		!config.passthroughDSAuthChallenge
	);
}

export interface Environment {
	isNative: boolean;
	nativePlatform: NativePlatform;
	nativeMessagingType?: 'url';
	nativeCommunicationMethod: 'messaging' | 'bridge';
	readonly citrixCloudConnected: boolean;
	setCitrixCloudConnectedStatus(connected: boolean): Promise<void>;
	initializeSync: boolean;
	nativeCapabilities: NativeCapabilities;
	nativeLogoffMethod: Extract<keyof ProvidedNativeFunctions, 'logOff' | 'attemptLogoff'>;
	isNativeDropdownNeeded: boolean;
	supportsLegacySettings: boolean;
	supportsNewSettings: boolean;
	useNativeUrlsForResourceIcons: boolean;
	supportsLocalApps: boolean;
	supportsLocalAppsWhitelist: boolean;
	supportsUserManagedLocalApps: boolean;
	supportsCitrixWorkspaceBrowserApp: boolean;
	supportsActivityManager: boolean;
	supportsShortcuts: boolean;
	menuPreferences: MenuPreferences;
	store: StoreDetails;
	showStoreFrontToWorkspaceMigrationGuideInfo: boolean;
	supportsAADAuth: boolean;
	cwaBuildVersion?: string;
	isGoOnlineSilentlySupported?: boolean;
	supportsInteractiveAuthOnly: boolean;
	build(): Promise<void>;
	ready(): void;
	writeTraceMessage(event: string, now: number, delta1: number, delta2: number): void;
	aotTraceMessage(message: AotType): void;
	workspaceToStoreFrontFallbackFeedbackResponse(
		switchNowClicked: boolean,
		switchToWorkspaceOnNextAppLaunchChecked: boolean
	): void;
	createSSOProxyClient(proxyUrl: string): ClientInstance;
	goOnline(): Promise<GoOnlineResult>;
	goOnlineSilently(): Promise<GoOnlineResult>;
	getOnlineStatus(): Promise<OnlineStatus>;
	supportsResource(resource: Resource): boolean;
	aboutBox(button: Element, showModal: ShowModal): void;
	ajax(config: AxiosRequestConfig): Promise<any>;
	launchShareFile(params: LaunchShareFileParams): boolean;
	launchMSTeams(url: string): Promise<void>;
	openWindow(
		url: string,
		openInTabOnWeb?: boolean,
		options?: OpenWindowOptions
	): Promise<string>;
	focusWindow(id: string): void;
	closeWindow(id: string): void;
	isWindowClosed(id: string): Promise<boolean>;
	launchViaIcaUrl(
		resource: Resource,
		config: WorkspaceConfiguration,
		url: string,
		jsonParams: object
	): Promise<void>;
	launchResource(options: LaunchResourceOptions): Promise<void>;
	launchApp2(
		resource: Resource,
		config: WorkspaceConfiguration,
		launchOptions: any,
		params: any,
		targetWindow?: Window,
		operationId?: string,
		launchProgressHandler?: LaunchProgressHandler
	): Promise<void>;
	launchLocalApp(resource: Resource, params: LaunchLocalAppParam): Promise<void>;
	launchApp(
		resource: Resource,
		config: WorkspaceConfiguration,
		launchOptions: any,
		params: any,
		targetWindow?: Window,
		operationId?: string,
		launchProgressHandler?: LaunchProgressHandler
	): Promise<void>;
	launchCitrixWorkspaceBrowserApp(
		params: LaunchCitrixWorkspaceBrowserAppParams
	): Promise<'failed' | 'success'>;
	subscribe(resource: Resource): Promise<void>;
	getLocalApps(): Promise<LocalApp[]>;
	getLocalAppWhitelist(): Promise<LocalApp[]>;
	installApp(resource: Resource): Promise<'failed' | 'success'>;
	configureSiri(resource: Resource): Promise<string>;
	removeApp(resource: Resource): Promise<void>;
	unsubscribe(resource: Resource): Promise<string>;
	uninstallApp(resource: Resource): Promise<'failed' | 'success'>;
	createShortcuts?(resources: Resource[]): Promise<boolean>;
	showSettings(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		width: number,
		height: number
	): void;
	showPreferences(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		width: number,
		height: number
	): void;
	settingsMenu2(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		width: number,
		height: number
	): void;
	settingsMenu(x1: number, y1: number, x2: number, y2: number): void;
	showAccounts(): void;
	switchToRoute(route: string): void;
	getNativeAccounts(): NativeAccounts;
	getAccounts(): string | NativeAccounts;
	getAccountsAsync(): Promise<string>;
	sendAction(id: string): void;
	attemptLogoff?(params: AttemptLogoffParams): Promise<AttemptLogoffResult>;
	logOff?(): void;
	canLogoff(params: CanLogoffParams): Promise<CanLogoffResult>;
	requestLogoff_complete(callbackID: string, params: RequestLogoffResult): void;
	getInstalledResources(resources: Resource[]): Promise<InstalledResourceData>;
	getSiriRegisteredApps(): Promise<SiriRegisteredResourceData>;
	fetchActivityList(): Promise<ActivityCardResourceList>;
	getInstalledApps3(resources: Resource[]): Promise<InstalledResourceData>;
	getInstalledApps2(resources: Resource[]): Promise<InstalledResourceData>;
	getInstalledApps(resources: Resource[]): Promise<InstalledResourceData>;
	noteRefreshStart(): void;
	refreshComplete(success: boolean): Promise<boolean>;
	refreshDone(succeeded: number, failed: number): Promise<boolean>;
	sync_refreshDone(succeeded: number, failed: number): boolean;
	noteRefreshDone(succeeded: number, failed: number): void;
	getFileUri(id: string): Promise<string>;
	sync_getFileUri(id: string): string;
	subscribeToNativeEvent<T>(listener: (event: Event<T>) => void): () => void;
	/** @deprecated replaced by dispatchEventToNative2 */
	dispatchEventToNative<T>(event: Event<T>): void;
	dispatchEventToNative2<T>(event: Event<T>): void;
	sendEventToNative<T>(event: Event<T>): void;
	setUserPreferences(preferences: UserPreferences): void;
	isNativeFeatureFlagEnabled(flagName: string): boolean;
	setAADResponse(authResultJson: string): void;
	setAADError(errorJson: string): void;
	performAuthorization(jsonPayload: string): Promise<MSALAuthorizationResultType>;
	setDaasUIMetadata(metadata: DaasUIMetadata): void;
	sync_setItem(key: string, value: string): void;
	setItem(key: string, value: string): Promise<void>;
	sync_getItem(key: string): string;
	getItem(key: string): Promise<string>;
}

const onBuilt: (() => void)[] = [];

const environment: Environment = {
	isNative: undefined,
	nativePlatform: undefined,
	nativeMessagingType,
	nativeCommunicationMethod,
	citrixCloudConnected: true,
	async setCitrixCloudConnectedStatus(connected: boolean) {
		(this as Writable<Environment>).citrixCloudConnected = connected;
		// Re-render the entire application after we switch in and out of connected mode.
		// This is easier than managing callbacks and ensures we don't miss notifying
		// a component at a higher level
		const { renderWorkspace } = await import('Workspace');
		renderWorkspace();
	},
	initializeSync: false,
	nativeCapabilities: {} as NativeCapabilities,
	isNativeDropdownNeeded: false,
	supportsLegacySettings: false,
	supportsNewSettings: false,
	nativeLogoffMethod: undefined,
	useNativeUrlsForResourceIcons: false,
	supportsLocalApps: false,
	supportsLocalAppsWhitelist: false,
	showStoreFrontToWorkspaceMigrationGuideInfo: false,
	cwaBuildVersion: undefined,
	isGoOnlineSilentlySupported: false,
	supportsInteractiveAuthOnly: false,
	menuPreferences: {
		about: true,
		logoff: true,
		preferences: false,
		refresh: false,
		accounts: false,
		accountSwitch: false,
		manageLocalApps: false,
	},
	store: {
		baseUri: getDefaultBaseUri(),
	} as StoreDetails,
	async build() {
		while (onBuilt.length) {
			const cb = onBuilt.shift();
			cb();
		}
		const { isNative, nativePlatform } = detectNativePlatform();

		const runtime = isNative ? 'native' : 'browser';
		monitoring.setSessionAttribute('runtime', runtime);
		monitoring.setSessionAttribute('nativePlatform', nativePlatform);
		setLoggingTags(
			{
				runtime,
				'native-platform': nativePlatform,
			},
			{
				global: true,
			}
		);

		this.isNative = isNative;
		this.nativePlatform = nativePlatform;
		if (nativePlatform) {
			switch (nativePlatform) {
				case 'windows':
					configureWindows(this);
					break;
				case 'android':
					configureAndroid(this);
					break;
				case 'ios':
					configureIos(this);
					break;
				case 'simulated':
					if (!IS_RELEASE) {
						configureSimulatedNativeEnvironment(this);
					}
					break;
			}
		}
		if (isNative) {
			await buildNativeEnvironment(this);
			monitoring.setSessionAttribute(
				'platformVersion',
				this.nativeCapabilities?.platform?.appversion || MonitoringData.NOT_AVAILABLE
			);
			if (this.supportsAADAuth) {
				initMsalSdk();
			}
		}
	},
	ready() {},
	writeTraceMessage() {},
	aotTraceMessage() {},
	workspaceToStoreFrontFallbackFeedbackResponse() {},
	createSSOProxyClient,
	goOnline() {
		return Promise.resolve(null);
	},
	goOnlineSilently() {
		return Promise.resolve(null);
	},
	getOnlineStatus() {
		return Promise.resolve(OnlineStatus.Online);
	},
	supportsResource(resource: Resource) {
		return supportsIcaLaunch(resource) || (resource.content && !isMobileApp(resource));
	},
	aboutBox(_button: Element, showModal: ShowModal) {
		showModal(<AboutWorkspace />);
	},
	ajax(config: AxiosRequestConfig) {
		return xhrAdapter(config).then((result: AxiosResponse) => {
			if (result.status !== 200) {
				const servername = result?.headers?.[citrixServerNameHeaderKey];
				const transactionid = config?.headers?.[citrixTransactionIdHeaderKey];
				if (servername && transactionid) {
					setInSessionStorage(transactionid, servername);
				}
			}
			addBreadcrumb({
				category: 'web_xhr',
				data: {
					method: config.method,
					url: config.url,
					status_code: result.status,
				},
				type: 'http',
			});
			/** In case cloud offline, network calls are blocked at this layer and will throw error.
			 * If we need to make network calls then we need to add X-CLOUD-OFFLINE-REQUEST in header
			 */
			if (
				(config.headers === undefined ||
					config.headers['X-CLOUD-OFFLINE-REQUEST'] === undefined) &&
				!environment.citrixCloudConnected
			) {
				const error = new Error(
					'Citrix Cloud is not connected. Running in an offline mode.'
				);
				error.name = 'CitrixCloudConnectedStatusError';
				return Promise.reject(error);
			}
			if (isAuthChallengePresent(result, config)) {
				loginExpired && loginExpired();
				const error = new Error('Login expired');
				error.name = 'LoginExpiration';
				return Promise.reject(error);
			}
			// currently cloud and onprem authentication flow are not identical so we are just reloading the page here, will be implemented in future : https://issues.citrite.net/browse/WSUI-9907
			if (isGatewaySessionExpired(result)) {
				location.href = '/';
			}
			return result;
		});
	},
	launchShareFile() {
		return false;
	},
	async launchMSTeams(url: string) {
		const theWindow = await this.openWindow(url.replace('msteams', 'https'));
		this.focusWindow(theWindow);
	},
	async openWindow(url: string, openInTabOnWeb = false, options?: OpenWindowOptions) {
		const theBrowserWindow = openInTabOnWeb
			? window.open(url, '_blank')
			: window.open(
					url,
					'_blank',
					formatPopupOptions({ ...defaultPopupOptions(), ...options })
			  );
		return trackBrowserWindow(theBrowserWindow);
	},
	focusWindow(id: string) {
		const theWindow = getBrowserWindowById(id);
		theWindow && typeof theWindow.focus === 'function' && theWindow.focus();
	},
	closeWindow(id: string) {
		const theWindow = getBrowserWindowById(id);
		theWindow && typeof theWindow.close === 'function' && theWindow.close();
		untrackBrowserWindowById(id);
	},
	async isWindowClosed(id: string) {
		const theWindow = getBrowserWindowById(id);
		if (theWindow && typeof theWindow.closed === 'boolean') {
			return theWindow.closed;
		}

		return undefined;
	},
	launchViaIcaUrl(
		resource: Resource,
		config: WorkspaceConfiguration,
		url: string,
		jsonParams: object
	) {
		return downloadICAFile(
			url,
			frameContent => {
				handleLaunchStatus(frameContent, config, resource, jsonParams);
			},
			config?.userInterface?.preventIcaDownloads,
			resource.name
		);
	},
	launchResource(options: LaunchResourceOptions) {
		// Call `launchApp2` from native if exists,
		// else call `launchApp` on native if exists
		// else fall back to web implementation
		return this.launchApp2(
			options.resource,
			options.workspaceConfiguration,
			options.launchOptions,
			options.params,
			options.targetWindow,
			options.operationId,
			options.launchProgressHandler
		);
	},
	launchApp2(
		resource: Resource,
		config: WorkspaceConfiguration,
		launchOptions: LaunchOptions,
		params: any,
		targetWindow?: Window,
		operationId?: string,
		launchProgressHandler?: LaunchProgressHandler
	) {
		return this.launchApp(
			resource,
			config,
			launchOptions,
			params,
			targetWindow,
			operationId,
			launchProgressHandler
		);
	},
	launchLocalApp() {
		return Promise.resolve();
	},
	launchApp(
		resource: Resource,
		config: WorkspaceConfiguration,
		launchOptions: LaunchOptions,
		params: any,
		targetWindow?: Window,
		operationId?: string,
		launchProgressHandler?: LaunchProgressHandler
	) {
		const isAutoLaunch = launchOptions ? launchOptions.isAutoLaunch : false;
		const chromeAppOptions = launchOptions ? launchOptions.chromeAppOptions : null;
		return resourceLaunch(
			resource,
			config,
			isAutoLaunch,
			chromeAppOptions,
			params,
			targetWindow,
			operationId,
			launchProgressHandler
		);
	},
	launchCitrixWorkspaceBrowserApp(_params: LaunchCitrixWorkspaceBrowserAppParams) {
		return Promise.resolve<'failed' | 'success'>(null);
	},
	subscribe(resource: Resource) {
		return postAsFormData<any>(resource.subscriptionurl, {
			data: {
				operation: 'subscribe',
				'dazzle:position': resource.position,
			},
		}).then(data => {
			const result = data.subscriptionstatus || data.status;
			if (!result || result !== subscriptionStatus.subscribed) {
				return Promise.reject(new Error('Resource subscription failed'));
			}

			return Promise.resolve();
		});
	},
	getLocalApps() {
		return Promise.resolve<LocalApp[]>([]);
	},
	getLocalAppWhitelist() {
		return Promise.resolve<LocalApp[]>([]);
	},
	installApp() {
		return Promise.resolve(null);
	},
	async configureSiri(): Promise<string> {
		return Promise.resolve<string>(null);
	},
	getSiriRegisteredApps() {
		return Promise.resolve<SiriRegisteredResourceData>({ resources: [] });
	},
	fetchActivityList() {
		return Promise.resolve<ActivityCardResourceList>({ resources: [] });
	},
	removeApp() {
		return Promise.resolve();
	},
	unsubscribe(resource: Resource) {
		return postAsFormData<any>(resource.subscriptionurl, {
			data: {
				operation: 'unsubscribe',
			},
		}).then(data => {
			return data.subscriptionstatus || data.status;
		});
	},
	uninstallApp() {
		return Promise.resolve<'failed' | 'success'>(null);
	},
	createShortcuts(_resources: Resource[]) {
		return Promise.resolve(false);
	},
	showSettings(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		width: number,
		height: number
	) {
		// Call `showPreferences` from native if exists,
		// else call `settingsMenu2` from native if exists,
		// else call `settingsMenu` on native if exists
		// else fall back to web implementation
		this.showPreferences(x1, y1, x2, y2, width, height);
	},
	showPreferences(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		width: number,
		height: number
	) {
		this.settingsMenu2(x1, y1, x2, y2, width, height);
	},
	settingsMenu2(x1: number, y1: number, x2: number, y2: number) {
		this.settingsMenu(x1, y1, x2, y2);
	},
	settingsMenu() {},
	showAccounts() {},
	switchToRoute(): void {},
	getNativeAccounts(): NativeAccounts {
		const accounts = this.getAccounts();
		if (typeof accounts === 'string') {
			return JSON.parse(accounts) as NativeAccounts;
		} else {
			return accounts;
		}
	},
	getAccounts(): ReturnType<ProvidedNativeFunctions['getAccounts']> {
		return '{ "accounts": [] }';
	},
	getAccountsAsync() {
		return Promise.resolve(JSON.stringify({ accounts: [] }));
	},
	sendAction() {},
	getInstalledResources(resources: Resource[]) {
		// Call 'getInstalledApps3' from native if exists,
		// else call `getInstalledApps2` from native if exists,
		// else call `getInstalledApps` on native if exists
		// else fall back to web implementation
		return this.getInstalledApps3(resources);
	},
	getInstalledApps3(resources: Resource[]) {
		return this.getInstalledApps2(resources);
	},
	getInstalledApps2(resources: Resource[]) {
		return this.getInstalledApps(resources);
	},
	getInstalledApps() {
		return Promise.resolve<InstalledResourceData>({ resources: [] });
	},
	noteRefreshStart() {},
	refreshComplete(success: boolean) {
		return success ? this.refreshDone(1, 0) : this.refreshDone(0, 1);
	},
	async refreshDone(succeeded: number, failed: number) {
		return this.sync_refreshDone(succeeded, failed);
	},
	sync_refreshDone(succeeded: number, failed: number) {
		this.noteRefreshDone(succeeded, failed);
		return true;
	},
	noteRefreshDone() {},
	async getFileUri(id: string) {
		return this.sync_getFileUri(id);
	},
	sync_getFileUri() {
		return '';
	},
	subscribeToNativeEvent() {
		return () => {};
	},
	dispatchEventToNative() {},
	dispatchEventToNative2(event) {
		return this.dispatchEventToNative(event);
	},
	sendEventToNative(event) {
		return this.dispatchEventToNative2(event);
	},
	setUserPreferences() {},
	requestLogoff_complete() {},
	canLogoff() {
		return Promise.resolve({ result: 'proceed' });
	},
	isNativeFeatureFlagEnabled(flagName: string) {
		return Boolean(this.nativeCapabilities.featureFlags?.[flagName]);
	},
	supportsUserManagedLocalApps: false,
	supportsCitrixWorkspaceBrowserApp: false,
	supportsActivityManager: false,
	supportsShortcuts: false,
	supportsAADAuth: false,
	setAADResponse(_authResultJson: string) {},
	setAADError(_errorJson: string): void {},
	async performAuthorization(_jsonPayload: string): Promise<MSALAuthorizationResultType> {
		return Promise.resolve({ status: 'failure' });
	},
	setDaasUIMetadata(_metadata: DaasUIMetadata): void {},
	sync_setItem(_key: string, _value: string): void {},
	async setItem(_key: string, _value: string): Promise<void> {
		return Promise.resolve();
	},
	sync_getItem(_key: string): string {
		return undefined;
	},
	async getItem(_key: string): Promise<string> {
		return Promise.resolve(undefined);
	},
};

// Development helper to avoid using environment before having a chance to bind to native methods.
if (process.env.NODE_ENV === 'development') {
	Object.keys(environment).forEach((key: keyof Environment) => {
		if (key === 'build') {
			return;
		}
		let _value = environment[key];
		Object.defineProperty(environment, key, {
			set: updated => (_value = updated),
			get: () => {
				throw new Error(
					`You are trying to use environment.${key} before the environment has been built.`
				);
			},
			configurable: true,
		});

		onBuilt.push(() => {
			Object.defineProperty(environment, key, {
				value: _value,
				writable: true,
				configurable: true,
			});
		});
	});
}

installToolFunction('getEnvironment', () => environment);

export { environment };
