import * as React from 'react';
import { StyleSheet } from 'aphrodite';
import {
	layout,
	NotificationBadgeIcon,
	palette,
	SmallLightText,
	Text,
	Tooltip,
	typography,
} from '@citrite/web-ui-component';
import { SkeletonLoaderShapes } from '@citrite/workspace-ui-platform-react';
import styled from '@emotion/styled';
import { PowerState } from 'App/Activity/Network/ActionsUtil';
import { useResourceState } from 'App/Activity/useResourceState';
import { getResourceSubText, tooltipDelay } from 'App/Screen/util';
import { PowerStateIndicator } from 'App/Tile/PowerStateIndicator';
import { TileIcon } from 'App/Tile/TileIcon';
import { Favorite } from 'Components/ResourceTile/Favorite';
import { useBranding } from 'Configuration/withBranding';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

const favoriteIconStyles = StyleSheet.create({
	icon: {
		padding: `0 ${layout.tinySpace} 0 0`,
	},
	loading: {
		padding: '2px',
	},
});

export const StyledLightText = styled(SmallLightText)`
	color: ${palette.black};
	opacity: 0.6;
	padding: 6px 0;
	font-weight: ${typography.weights.regular};
	line-height: 11px;
	min-width: fit-content;
`;

const StyledDiv = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 80%;
	max-width: 80%;
`;

const InnerStyledDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0 ${layout.mediumSpace};
	width: 80%;
`;

const StyledNotificationBadge = styled(NotificationBadgeIcon)`
	display: inline-block;
	margin-right: ${layout.tinySpace};
`;

export const SubTextDiv = styled.div<{ isSmallFormFactor: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	${props =>
		props.isSmallFormFactor &&
		`
		justify-content: flex-start;	
	`}
`;

export const StyledText = styled(Text)<{ isSmallFormFactor: boolean }>`
	text-align: center;
	font-weight: ${typography.weights.semiBold};
	color: ${palette.black};
	height: ${layout.largeSpace};
	max-height: ${layout.largeSpace};
	overflow: hidden;
	margin-top: ${layout.mediumSpace};
	width: 100%;
	line-height: ${layout.mediumSpace};
	${props =>
		props.isSmallFormFactor
			? `
		margin: 0;
		display: block;
		text-align: start;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		height: 100%;
	`
			: `
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
  		height: inherit;
	    display: -webkit-box;
		-webkit-box-orient: vertical;
		word-break: break-word;
	`};
`;

const SmallFactorFavoriteWrapper = styled.div`
	display: flex;
`;
interface Props {
	resource: Resource;
	inputRef?: React.RefObject<HTMLInputElement>;
	isSmallFormFactor: boolean;
	isResourceAvailable: boolean;
	hasSubscriptionsEnabled: boolean;
}

export enum ResourceType {
	WebApp = 'Citrix.MPS.Document',
	VirtualApp = 'Citrix.MPS.Application',
	Desktop = 'Citrix.MPS.Desktop',
}

export const Container = ({ children, isSmallFormFactor }: any) =>
	isSmallFormFactor ? <StyledDiv>{children}</StyledDiv> : <>{children}</>;

export const InnerContainer = ({ isSmallFormFactor, children }: any) =>
	isSmallFormFactor ? <InnerStyledDiv>{children}</InnerStyledDiv> : <>{children}</>;

const { LoadingBar } = SkeletonLoaderShapes;

function ResourceDetails({
	resource,
	inputRef,
	isSmallFormFactor,
	isResourceAvailable,
	hasSubscriptionsEnabled,
}: Props) {
	const resourceName = <>{resource.name}</>;
	const isNewResourceHighlightEnabled = useFeatureCanary(
		FeatureFlag.HighlightNewResource
	);
	const branding = useBranding();
	const resourceState = useResourceState(resource);
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);

	const highlightNewResource =
		!isSmallFormFactor && isNewResourceHighlightEnabled && !!resource?.isnewresource;

	const getResourceStatus = () => {
		if (
			!resource?.canQueryMachineState ||
			resourceState === PowerState.NOT_SUPPORTED ||
			!isMachinePowerStateEnabled
		) {
			return (
				<StyledLightText data-testid="resource-type">
					{getResourceSubText(resource.type)}
				</StyledLightText>
			);
		} else if (resourceState === 'loading') {
			return <LoadingBar width="60%" />;
		} else {
			return (
				<PowerStateIndicator resourceType={resource.type} powerState={resourceState} />
			);
		}
	};

	return (
		<Container isSmallFormFactor={isSmallFormFactor}>
			<TileIcon resource={resource} />
			<InnerContainer isSmallFormFactor={isSmallFormFactor}>
				<StyledText isSmallFormFactor={isSmallFormFactor}>
					{highlightNewResource && (
						<StyledNotificationBadge
							data-testid="new-app-notifier"
							color={branding.styles.AccentColor}
						/>
					)}
					{isResourceAvailable ? (
						<Tooltip hoverTrigger={resourceName} delay={tooltipDelay}>
							{resourceName}
						</Tooltip>
					) : (
						resourceName
					)}
				</StyledText>
				<SubTextDiv isSmallFormFactor={isSmallFormFactor}>
					{isSmallFormFactor && isResourceAvailable && hasSubscriptionsEnabled && (
						<SmallFactorFavoriteWrapper onClick={e => e.stopPropagation()}>
							<Favorite
								{...resource}
								iconSize={8}
								styles={favoriteIconStyles}
								inputRef={inputRef}
							/>
						</SmallFactorFavoriteWrapper>
					)}
					{getResourceStatus()}
				</SubTextDiv>
			</InnerContainer>
		</Container>
	);
}

export const ResourceDetailsWithFavoriteRef = React.forwardRef(
	(props: Props, ref: React.RefObject<HTMLInputElement>) => (
		<ResourceDetails {...props} inputRef={ref} />
	)
);
