import * as React from 'react';
import { LoadableProvider } from 'Components/LoadableProvider';
import { LaunchResourceOptions } from 'Environment';
import { LaunchStatus } from 'Environment/launchResource/launchResource';
import {
	InstalledResource,
	Resource,
	subscriptionStatus,
} from 'Workspace/ResourceProvider/resourceTypes';
export type LaunchProgressDetails = {
	resourceId: string;
	status: LaunchStatus;
	onCancel?: () => void;
};
type ResourceLaunchProgressMap = {
	[key: string]: LaunchProgressDetails;
};
type UpdateSession = (currentSession: ResourceContext) => Partial<ResourceContext>;
type UpdateSessionFunction = (
	callbackOrData: UpdateSession | Partial<ResourceContext>
) => void;
export interface ResourceContext {
	isUnauthenticatedStore: boolean;
	resources: Resource[];
	installedResources: InstalledResource[];
	launchInProgressIds: string[];
	launchProgress: ResourceLaunchProgressMap;
	desktopRestartInProgressIds: string[];
	preferLeaseLaunchIds: string[];
	setLoading(loading: boolean): void;
	updateSession: UpdateSessionFunction;
	setSubscriptionStatus(resourceId: string, subscriptionStatus: subscriptionStatus): void;
	setSiriRegistrationStatus(resourceId: string, status: boolean): void;
	makeResourceMostRecent(resourceId: string): void;
	startRecordingResourceUpdates(): void;
	stopRecordingResourceUpdates(): void;
	applyRecordedResourceUpdates(resources: Resource[]): Resource[];
	isInstalled(id: string): boolean;
	isUpgradeAvailable(id: string): boolean;
	isInProgress(id: string): boolean;
	subscriptionsEnabled(): boolean;
	tryLaunchViaBrowserExtension(
		launchResourceOptions: LaunchResourceOptions
	): Promise<any>;
	tryLaunchViaChromeApp(launchResourceOptions: LaunchResourceOptions): Promise<any>;
	shouldBrowserExtensionLaunchResource(resource: Resource): Promise<boolean>;
	shouldChromeAppLaunchResource(resource: Resource): Promise<boolean>;
	setMultipleResourceSubscriptionStatus(
		resourceIds: string[],
		subscriptionStatus: subscriptionStatus
	): void;
	filesSSOUrl: string;
	isNetworkValidatedEnumeration?: boolean;
	disallowICADownload?: boolean;
	citrixBrowserResourceID?: string;
	isSubscriptionEnabled?: boolean;
	devicePosture?: DevicePosture;
	serverName?: string | null;
}

export interface DevicePosture {
	state: string;
	scanTransactionId: string;
	scanTime?: string;
	errorCode?: string;
}

export type ResourceContextProvider = LoadableProvider<ResourceContext>;
export const LoadableResourceContext = React.createContext<ResourceContextProvider>({
	loading: true,
	value: null,
});
