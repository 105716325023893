import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { getAppleBadge } from 'App/Settings/Tools/Tool/getAppleBadge';
import { getGoogleBadge } from 'App/Settings/Tools/Tool/getGoogleBadge';
import { platformSpecificClientDisplayNames } from 'Components/Detection/nativeClientName';
import { platform } from 'Environment/launchResource/constants';
import {
	getCurrentPlatform,
	isMobileOrTabletDevice,
} from 'Environment/launchResource/device/currentPlatform';
import { PlatformData } from 'Workspace/MandateNative/MandateNativeScreen';

export type PlatformSpecificLinks = {
	platformData?: PlatformData;
	canDownload: boolean;
	downloadLink?: string;
	guideLink?: string;
};

const androiCWAdDownloadLink =
	'https://play.google.com/store/apps/details?id=com.citrix.Receiver';
const iosCWADownloadLink =
	'https://itunes.apple.com/us/app/citrix-receiver/id363501921?mt=8';

const defaultDownloadLink = 'https://www.citrix.com/downloads/workspace-app/';

export const getDownloadLink = (
	config: WorkspaceConfiguration,
	currentPlatform: platform
) => {
	if (isMobileOrTabletDevice()) {
		return getMobileDownloadLinks(currentPlatform);
	}
	if (config.pluginAssistant.downloadPageUrl) {
		return config.pluginAssistant.downloadPageUrl;
	}
	switch (currentPlatform) {
		case platform.mac:
			return config?.pluginAssistant?.macOS?.path || defaultDownloadLink;
		case platform.windows:
			return config?.pluginAssistant?.win32?.path || defaultDownloadLink;
	}

	return defaultDownloadLink;
};

const getMobileDownloadLinks = (currentPlatform: platform) => {
	switch (currentPlatform) {
		case platform.android:
		case platform.androidTablet:
			return androiCWAdDownloadLink;
		case platform.ios:
		case platform.ipadOS:
			return iosCWADownloadLink;
	}
	return defaultDownloadLink;
};

const guideLinks: { [key: string]: string } = {
	windows:
		'https://help-docs.citrix.com/en-us/citrix-workspace-app/windows/CWA-0001.html',
	android:
		'https://help-docs.citrix.com/en-us/citrix-workspace-app/android/CWA-0002.html',
	androidTablet:
		'https://help-docs.citrix.com/en-us/citrix-workspace-app/android/CWA-0002.html',
	chromeOS:
		'https://help-docs.citrix.com/en-us/citrix-workspace-app/chrome/CWA-0003.html',
	ios: 'https://help-docs.citrix.com/en-us/citrix-workspace-app/ios/CWA-0005.html',
	ipadOS: 'https://help-docs.citrix.com/en-us/citrix-workspace-app/ios/CWA-0005.html',
	linux: 'https://help-docs.citrix.com/en-us/citrix-workspace-app/linux/CWA-0006.html',
	mac: 'https://help-docs.citrix.com/en-us/citrix-workspace-app/mac/CWA-0007.html',
};

const getPlatformData = (currentPlatform: platform): PlatformData => {
	return {
		platform: platform[currentPlatform],
		platformDisplayName:
			platformSpecificClientDisplayNames.find(item => item.platform === currentPlatform)
				?.name || '',
		isMobileOrTablet: isMobileOrTabletDevice(),
	};
};

export const getGuideLink = () => {
	const currentPlatform = getCurrentPlatform();
	return guideLinks[platform[currentPlatform]];
};

export const getCurrentPlatformLinkData = (
	config: WorkspaceConfiguration
): PlatformSpecificLinks => {
	const currentPlatform = getCurrentPlatform();
	const linkData: PlatformSpecificLinks = { canDownload: false };

	if (config) {
		linkData.platformData = getPlatformData(currentPlatform);
		linkData.canDownload = config?.pluginAssistant?.enabled.toLowerCase() === 'true';
		linkData.downloadLink = getDownloadLink(config, currentPlatform);
		linkData.guideLink = guideLinks[platform[currentPlatform]];
	}

	return linkData;
};

export const getMobileAppLogo = () => {
	const currentPlatform = getCurrentPlatform();
	switch (currentPlatform) {
		case platform.android:
		case platform.androidTablet:
			return getGoogleBadge();
		case platform.ios:
		case platform.ipadOS:
			return getAppleBadge();
		default:
			return null;
	}
};
