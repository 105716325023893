import * as React from 'react';
import {
	BreakpointsProps,
	useBreakpoints,
	withModals,
	WithModalsProps,
} from '@citrite/web-ui-component';
import {
	hasFeatureCanary,
	IntegrationCapability,
	IntegrationContext,
	WorkspaceConfiguration,
} from '@citrite/workspace-ui-platform';
import styled from '@emotion/styled';
import { trackPageLoad } from 'analytics';
import { History, Location, UnregisterCallback } from 'history';
import { enableES5 } from 'immer';
import { DragDropContext as withDragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import { logError } from 'remoteLogging';
import { BannerPosition } from 'AdminNotificationService/createAdminNotificationClient';
import { useAppResourceReportingEffect } from 'App/Apps/useAppResourceReporting';
import { BottomNavBar } from 'App/BottomNav';
import { getErrorPageUrlForLanguage } from 'App/Common/routing';
import { DefaultErrorTab } from 'App/DefaultErrorTab';
import { useDesktopOnlyReportingEffect } from 'App/DesktopOnly/useDesktopOnlyReporting';
import { DaasHeader } from 'App/Header';
import { Greeting } from 'App/Header/Greeting';
import { HomepageSwitchHandler } from 'App/HomepageSwitchHandler';
import { Navigation } from 'App/Navigation';
import { isHorizontalTabRoute } from 'App/Navigation/checkHorizontalTabRoute';
import { LaunchProgressWindow } from 'App/ProgressAlert';
import { RefreshBanner } from 'App/RefreshBanner';
import {
	useAnalyticsInitialization,
	useMonitoringUserReporting,
	useRemoteLoggingUserReporting,
} from 'App/Reporting';
import { useConfiguredServicesReporting } from 'App/Reporting/useConfiguredServicesReporting';
import { usePinnedLinksReporting } from 'App/Reporting/usePinnedLinksReporting';
import { useStorefrontFallbackReporting } from 'App/Reporting/useStorefrontFallbackReporting';
import { useSendDaasUIMetadataToNative } from 'App/Screen/useSendDaasUIMetadataToNative';
import { AndroidBrowserAppBanner } from 'Components/AndroidBrowserAppBanner';
import {
	AdminNotificationBanner,
	AppBody,
	AppContent,
	AppFooter,
	Application,
	OfflineBanner,
} from 'Components/AppLayout';
import { AppHeader } from 'Components/AppLayout/AppHeader';
import { LogonExpired } from 'Components/LogonExpired';
import { CustomBlockingNotification } from 'Components/Modals/CustomBlockingNotification';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { environment, setLoginExpiredCallback } from 'Environment';
import { FeatureFlag } from 'Environment/FeatureFlag';
import {
	isAndroidBrowser,
	isDesktopBrowser,
	isNativeMobileClient,
	isNativeMobileOrTabletClient,
	isNativeWidget,
} from 'Environment/launchResource/device';
import { NativeAnalyticsTrackingEventSubscriber } from 'Environment/NativeAnalyticsTrackingEventSubscriber';
import { useIntegrations } from 'Integrations/useIntegrations';
import {
	callHomeForShield,
	initializeWasmForShieldFlow,
	isHTML5ShieldEnabled,
} from 'LeaseWorkflow';
import { ErrorLevel } from 'Loggers/LoggingProvider';
import { startClient, stopClient } from 'pushEventService';
import { MastheadState } from 'Router/ReactRoute';
import { useUserContext, WithUserContextProps } from 'Workspace/UserContext';
import { SessionActivity } from './Common/SessionActivity';
import { RouteLoader } from './RouteLoader';

const defaultRefreshPageFn = () => {
	if (process.env.NODE_ENV === 'development') {
		console.warn(new Error('Current page does not have a refresh function').message);
	}
};

export interface InternalProps
	extends WithModalsProps,
		ExternalProps,
		WithUserContextProps {
	workspaceConfiguration: WorkspaceConfiguration;
	breakpoints: BreakpointsProps;
	history: History;
	location: Location;
	integrations: IntegrationContext;
}

export interface ExternalProps {
	refreshingResources: boolean;
}

const AppBodyHeader = styled.div`
	width: 100%;
	max-width: 1200px;
	box-sizing: border-box;
	margin: 0 auto;
`;

interface State extends MastheadState {
	hasError?: boolean;
}

class _App extends React.Component<InternalProps, State> {
	public state: State = {
		mobileMastheadTitle: '',
	};

	public isNativeWidget = isNativeWidget();

	private get hasPushFeature() {
		return hasFeatureCanary(
			this.props.workspaceConfiguration,
			FeatureFlag.WsuiEnablePushNotifications
		);
	}

	private refreshPage = defaultRefreshPageFn;

	private stopListening: UnregisterCallback = () => {};

	public render(): JSX.Element {
		const { breakpoints } = this.props;
		const shouldShowTabBar =
			(isNativeMobileClient() || !this.isNativeWidget) && breakpoints.screenSize.onMobile;
		const isLargeFormFactor = !breakpoints.screenSize.onMobile;
		const location = this.props.location;
		const displayDaasAdminInfoBanner =
			environment.citrixCloudConnected && !this.isNativeWidget;
		return (
			<Application>
				<NativeAnalyticsTrackingEventSubscriber />
				{!environment.citrixCloudConnected && <OfflineBanner />}
				{!this.isNativeWidget && <DaasHeader />}
				<AppHeader />
				{shouldShowTabBar && <BottomNavBar refreshPage={this.refreshPage} />}
				<AppBodyHeader>
					{isLargeFormFactor && isHorizontalTabRoute(location) && <Greeting />}
					{!this.isNativeWidget && isLargeFormFactor && <Navigation />}
				</AppBodyHeader>
				<AppBody>
					{this.state.hasError ? (
						<DefaultErrorTab backToDashboard={this.backToDashboard} />
					) : (
						<AppContent>
							{this.props.refreshingResources &&
								!isNativeMobileOrTabletClient() &&
								!this.isNativeWidget && <RefreshBanner />}
							<RouteLoader
								setMobileMastheadTitle={this.setMobilePageTitle}
								setRefreshPageFn={fn => (this.refreshPage = fn)}
								clearRefreshPageFn={() => (this.refreshPage = defaultRefreshPageFn)}
							/>
							{displayDaasAdminInfoBanner && (
								<AdminNotificationBanner intendedPosition={BannerPosition.Bottom} />
							)}
							{isLargeFormFactor && <AppFooter />}
						</AppContent>
					)}
				</AppBody>
				{!environment.isNative && <SessionActivity />}
				{isAndroidBrowser() && (
					<AndroidBrowserAppBanner isNativeWidget={this.isNativeWidget} />
				)}
				<CustomBlockingNotification userId={this.props.userContext.userDetails?.userId} />
				<HomepageSwitchHandler />
				{isDesktopBrowser() && <LaunchProgressWindow />}
			</Application>
		);
	}

	public componentDidMount() {
		enableES5();
		setLoginExpiredCallback(() => this.props.showModal(<LogonExpired />));

		this.stopListening = this.props.history.listen(() => {
			trackPageLoad();
		});

		if (this.hasPushFeature) {
			startClient(this.props.workspaceConfiguration);
		}

		this.preventNavigationByDrag();

		const event = {
			id: 'WSUI_Loaded',
			type: 'wsui/loaded',
			payload: { WSUI_Loaded: true },
		};
		environment.dispatchEventToNative2(event);

		this.props.integrations
			.resolveByCapability(IntegrationCapability.appStartup)
			.forEach(async ({ resolver }) => {
				const capability = await resolver;
				capability.onAppStart();
			});
		if (isHTML5ShieldEnabled(this.props.workspaceConfiguration)) {
			initializeWasmForShieldFlow().then(() => {
				callHomeForShield();
			});
		}
	}

	public componentDidUpdate(prevProps: InternalProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState((state, _) => ({ ...state, hasError: false }));
		}
	}

	public componentDidCatch(error: Error, errorInfo: object) {
		logError(error, { additionalContext: { errorInfo, level: ErrorLevel.FATAL } });
		if (this.state.hasError) {
			location.assign(getErrorPageUrlForLanguage('jserror'));
		} else {
			this.setState((state, _) => ({ ...state, hasError: true }));
		}
	}

	public componentWillUnmount() {
		this.stopListening();
		if (this.hasPushFeature) {
			stopClient();
		}
	}

	private backToDashboard = () => {
		this.props.history.push('/');
	};

	private preventNavigationByDrag = () => {
		if (environment.isNative) {
			document.addEventListener('drop', e => {
				e.preventDefault();
			});
			document.addEventListener('dragover', e => {
				e.preventDefault();
			});
		}
	};

	private setMobilePageTitle = (mastheadTitle: string) => {
		if (this.state.mobileMastheadTitle !== mastheadTitle) {
			this.setState((state, _) => ({ ...state, mobileMastheadTitle: mastheadTitle }));
		}
	};
}

const AppWithHOCs = withModals(withDragDropContext(HTML5Backend)(_App));

export function App(props: ExternalProps) {
	const { workspaceConfiguration } = useConfigurationContext();
	const breakpoints = useBreakpoints();
	const history = useHistory();
	const location = useLocation();
	const integrations = useIntegrations();
	useAnalyticsInitialization();
	useDesktopOnlyReportingEffect();
	useAppResourceReportingEffect();
	useMonitoringUserReporting();
	usePinnedLinksReporting();
	useRemoteLoggingUserReporting();
	useConfiguredServicesReporting();
	useStorefrontFallbackReporting();
	useSendDaasUIMetadataToNative();
	const userContext = useUserContext();
	return (
		<AppWithHOCs
			{...props}
			breakpoints={breakpoints}
			workspaceConfiguration={workspaceConfiguration}
			history={history}
			location={location}
			integrations={integrations}
			userContext={userContext}
		/>
	);
}
