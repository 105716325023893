import { logError, logInfo } from 'remoteLogging';
import { bxtnIdHelper } from 'Environment/BrowserExtension/BrowserExtensionIDHelper';
import { createBrowserExtensionMessage } from 'Environment/BrowserExtension/browserExtensionMessageHelper';
import { ShieldBrowserExtensionMessageTransport } from 'Environment/BrowserExtension/ShieldBrowserExtensionMessageTransport';
import {
	BrowserExtensionErrorReason,
	BrowserExtensionMessage,
	BrowserExtensionMessageType,
	BrowserExtensionResponse,
} from 'Environment/BrowserExtension/types';
import { isChromeRuntimeSendMessageAvailable } from 'Workspace/BrowserExtension/browserExtensionHelperMethods';

class ChromeRuntimeTransport implements ShieldBrowserExtensionMessageTransport {
	public sendMessageToBrowserExtension = (
		workload: any,
		type: BrowserExtensionMessageType
	) => {
		const message: BrowserExtensionMessage = createBrowserExtensionMessage(
			workload,
			type
		);
		return new Promise<BrowserExtensionResponse>((resolve, reject) => {
			if (!isChromeRuntimeSendMessageAvailable()) {
				logInfo('Chrome runtime is not available');
				const error = new Error('Chrome runtime is not available');
				error.name = BrowserExtensionErrorReason.Chrome_Runtime_Not_Available;
				reject(error);
				return;
			}
			try {
				window.chrome.runtime.sendMessage(
					bxtnIdHelper.getID(),
					message,
					async (response: BrowserExtensionResponse) => {
						try {
							const result = await handleBrowserExtensionResponse(
								response,
								message,
								this.sendMessageToBrowserExtension
							);
							resolve(result);
						} catch (error) {
							reject(error);
						}

						if (window.chrome.runtime.lastError) {
							// Reading the runtime.lastError property prevents an asynchronous console error from being logged when the Chrome browser extension is unavailable.
							// Unavailable extension errors are already handled by other means.
						}
					}
				);
			} catch (error) {
				logError(error);
				const newError = new Error(`chrome.runtime.sendMessage failed: ${error}`);
				newError.name = BrowserExtensionErrorReason.Chrome_Runtime_SendMessage_Failure;
				reject(newError);
			}
		});
	};
}

export function handleBrowserExtensionResponse(
	response: BrowserExtensionResponse | undefined | null,
	retryMsg: BrowserExtensionMessage,
	transport: (
		workload: any,
		type: BrowserExtensionMessageType
	) => Promise<BrowserExtensionResponse>
) {
	if (!response) {
		const error = new Error('Invalid response provided.');
		error.name = BrowserExtensionErrorReason.Unavailable;
		return Promise.reject(error);
	} else if (
		response.errorReason === BrowserExtensionErrorReason.PipeRenegotiated_TryAgain
	) {
		return transport(retryMsg.payload, retryMsg.header.type);
	} else if (response.status !== 'successful') {
		const error = new Error('Response status was not successful');
		error.name = BrowserExtensionErrorReason.Unsuccessful;
		error['status'] = response.status;
		return Promise.reject(error);
	} else {
		return Promise.resolve(response);
	}
}
export const sendMessageViaChromeRuntime = new ChromeRuntimeTransport()
	.sendMessageToBrowserExtension;
