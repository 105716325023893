import { useCallback, useEffect, useMemo, useState } from 'react';
import { WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { debounce } from 'lodash';
import { fetchMachineList } from 'App/Activity/Network/MachineList';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import { useVisibility } from 'App/Activity/useVisibility';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { getFromSessionStorage, setInSessionStorage } from 'javascript/sf/Storage';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';
import { useUserContext } from 'Workspace/UserContext';

const LAST_MACHINE_DATA_FETCH_TIME = 'lastMachineDataFetchTime';
const DEBOUNCE_TIME = 5000;
const POLLING_TIME = 20000;

const getMachinesEndpoint = (workspaceConfiguration: WorkspaceConfiguration) =>
	workspaceConfiguration?.storeProxy?.machinesProxy?.listMachinesURL;
const getMachineResourceIds = (resources: Resource[]) =>
	resources
		?.filter(({ canQueryMachineState }) => canQueryMachineState)
		.map(({ id }) => id);

//TODO: Add more UTs to cover all the scenarios properly and accordingly do the necessary refactoring
export const useMachineStatus = () => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const { workspaceConfiguration } = useConfigurationContext();
	const { hasLoggedIn } = useUserContext();
	const {
		value: { resources },
	} = useLoadableResourceContext();
	const [loading, setLoading] = useState(false);
	const [machines, setMachines] = useState<(Machine | MachineError)[]>([]);
	const [queryableMachineIds, setQueryableMachineIds] = useState<string[]>([]);
	const [machineListEndpoint, setMachineListEndpoint] = useState<string>('');

	const isVisible = useVisibility();

	useEffect(() => {
		setQueryableMachineIds(getMachineResourceIds(resources));
	}, [resources]);

	useEffect(() => {
		setMachineListEndpoint(getMachinesEndpoint(workspaceConfiguration));
	}, [workspaceConfiguration]);

	const fetchMachinesFromNetwork = useCallback(
		async (url: string, desktopIds: string[]) => {
			const hasUserLoggedIn = await hasLoggedIn();
			if (hasUserLoggedIn) {
				setLoading(true);
				try {
					setInSessionStorage(LAST_MACHINE_DATA_FETCH_TIME, new Date().getTime());
					const machinesStatus = await fetchMachineList(url, desktopIds);
					setMachines(machinesStatus?.machines || []);
				} finally {
					setLoading(false);
				}
			}
		},
		[hasLoggedIn]
	);

	const fetchMachines = useCallback(
		async (url: string, desktopIds: string[], forceNetworkCall = false) => {
			if (isVisible) {
				const currentTime = new Date().getTime();
				const lastMachineDataFetchTime =
					Number(getFromSessionStorage<number>(LAST_MACHINE_DATA_FETCH_TIME)) ||
					currentTime - POLLING_TIME;
				const itsBeenMoreThan2Mins =
					currentTime - lastMachineDataFetchTime > POLLING_TIME - 50;
				if (forceNetworkCall || itsBeenMoreThan2Mins) {
					fetchMachinesFromNetwork(url, desktopIds);
				}
			}
		},
		[fetchMachinesFromNetwork, isVisible]
	);

	const debouncedFetchMachines = useMemo(
		() => debounce(fetchMachines, DEBOUNCE_TIME),
		[fetchMachines]
	);

	useEffect(() => {
		if (
			isMachinePowerStateEnabled &&
			isVisible &&
			queryableMachineIds?.length > 0 &&
			machineListEndpoint
		) {
			const interval = setInterval(
				() => fetchMachines(machineListEndpoint, queryableMachineIds, true),
				POLLING_TIME
			);
			return () => clearInterval(interval);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		fetchMachines,
		isMachinePowerStateEnabled,
		queryableMachineIds,
		machineListEndpoint,
	]);

	useEffect(() => {
		if (
			isMachinePowerStateEnabled &&
			queryableMachineIds?.length > 0 &&
			machineListEndpoint
		) {
			debouncedFetchMachines(machineListEndpoint, queryableMachineIds);
		}
	}, [
		isMachinePowerStateEnabled,
		debouncedFetchMachines,
		queryableMachineIds,
		machineListEndpoint,
	]);

	return { loading, machineData: machines };
};
