import * as React from 'react';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { StyledSecondaryContent } from './styled';

export const SecondaryContent: React.FC = props => {
	const height = useClientHeight();
	return (
		<StyledSecondaryContent clientHeight={height}>
			{props.children}
		</StyledSecondaryContent>
	);
};
