import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	palette,
} from '@citrite/web-ui-component';
import styled from '@emotion/styled';

export const StyledModalHeader = styled(ModalHeader)`
	border-bottom: none;
`;

export const StyledModalTitle = styled(ModalTitle)`
	font-size: 29px;
	color: ${palette.black};
	white-space: pre-wrap;
`;

export const StyledModalBody = styled(ModalBody)`
	padding-top: 0px;
	padding-bottom: 0px;
	overflow-y: hidden;
`;

export const StyledModalFooter = styled(ModalFooter)`
	padding-top: 24px;
`;

export const StyledModal = styled(Modal)`
	max-height: 90%;
`;

export const Message = styled.div`
	overflow-y: auto;
`;
