import React from 'react';
import { t } from '@citrite/translate';
import { CitrixLogoIcon, palette, typography } from '@citrite/web-ui-component';
import styled from '@emotion/styled';

const StyledMessageSpan = styled.span`
	font-size: ${typography.sizes.xsml};
	font-weight: ${typography.weights.regular};
	color: ${palette.grey900};
	padding: 4px 4px 0 0;
	height: 12px;
`;

export const CitrixBranding = () => {
	return (
		<div>
			<StyledMessageSpan>{`${t('Workspace:powered_by')}`}</StyledMessageSpan>
			<CitrixLogoIcon height={12} width={35} />
		</div>
	);
};
