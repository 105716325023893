import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { ShimmerLoading } from '@citrite/workspace-ui-platform-react';

const { ShimmerLoader } = ShimmerLoading;

const styles = StyleSheet.create({
	container: {
		alignItems: 'center',
		backgroundColor: 'inherit',
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
	},
});

export function AppLoader() {
	return (
		<div className={css(styles.container)}>
			<ShimmerLoader />
		</div>
	);
}
