import { useEffect, useState } from 'react';
import { first } from 'lodash';
import { isInEnum } from 'App/Activity/components/Utility';
import {
	MachineAction,
	MachineData,
	Session,
	useResourceManagerContext,
} from 'App/Activity/ResourceManagerContext';
import { useDesktopActions } from 'App/Activity/useDesktopActions';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

export const useResourceActions = (resource: Resource) => {
	const context = useResourceManagerContext();
	const [resourceSession, setResourceSession] = useState<Session>();
	const [resourceMachine, setResourceMachine] = useState<MachineData>();

	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);

	useEffect(() => {
		if (!isMachinePowerStateEnabled) {
			return;
		}
		const machine = context?.machineStatus?.machineData?.find(
			({ resourceId }) => resourceId === resource.id
		);
		setResourceMachine(machine ? machine.machineData : undefined);
	}, [context?.machineStatus?.machineData, isMachinePowerStateEnabled, resource]);

	useEffect(() => {
		if (!isMachinePowerStateEnabled) {
			return;
		}
		const allSessions = [
			...context?.localSessions?.sessions,
			...context?.remoteSessions?.sessions,
			...context?.hibernatedSessions?.sessions,
		];
		const session = allSessions.find(
			// TODO: should we have canQueryMachineState check?
			({ applications }) => first(applications)?.resource?.id === resource.id
		);
		setResourceSession(session);
	}, [
		context?.localSessions?.sessions,
		context?.remoteSessions?.sessions,
		context?.hibernatedSessions?.sessions,
		resource,
		isMachinePowerStateEnabled,
	]);

	const actions =
		useDesktopActions({
			session: resourceSession,
			machine: resourceSession?.machineData || resourceMachine,
		}) || [];

	const filteredActions = actions.filter(action => isInEnum(action, MachineAction));

	return {
		session: resourceSession,
		actions: filteredActions,
		machine: resourceSession?.machineData || resourceMachine,
	};
};
