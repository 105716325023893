import React from 'react';
import { layout } from '@citrite/web-ui-component';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { bottomNavBarHeight } from 'App/BottomNav/dimensions';
import { useFormFactor } from 'App/Header/useFormFactor';
import { useClientHeight } from 'Components/AppLayout/useClientHeight';
import { useHTMLElementProperties } from 'Components/AppLayout/useHTMLElementProperties';
import { ConditionalRenderer } from 'Components/ConditionalRenderer';

const footerHeight = layout.largeSpace;
const StyledDiv = styled.div<{
	isSmallFormFactor: boolean;
	clientHeight: string;
	offsetTop: number;
	offsetBottom: number;
}>`
	overflow-y: auto;
	${props =>
		props.isSmallFormFactor
			? css`
					padding: ${layout.mediumSpace};
					display: flex;
					flex-direction: column;
					width: auto;
					margin-bottom: ${bottomNavBarHeight};
					height: calc(
						${props.clientHeight} - ${props.offsetTop}px - ${layout.mediumSpace} -
							${layout.mediumSpace} - ${bottomNavBarHeight}
					);
			  `
			: css`
					height: calc(
						${props.clientHeight} - ${props.offsetTop}px - ${layout.mediumLargeSpace} -
							${layout.mediumLargeSpace} - ${footerHeight} - ${props.offsetBottom}px
					);
					padding: ${layout.mediumLargeSpace} 64px;
					position: relative;
					scrollbar-width: thin;
			  `};
`;
/**	CSS explanation:
 *	The height is being calculated by taking into consideration the
 *	height above and below the Container (offsetTop and offsetBottom) and also
 *  the top and bottom padding of the Container component (layout.mediumLargeSpace)
 *  and the height of the Footer component excluding the margin and padding(16px).
 */

const StyledInnerContainer = styled.div`
	max-width: 1060px;
	margin: 0 auto;
`;
interface ContainerProps {
	id?: string;
	children: React.ReactElement | React.ReactElement[];
}

export const Container = ({ children, id }: ContainerProps) => {
	const { isSmallFormFactor, isLargeFormFactor } = useFormFactor();
	const clientHeight = useClientHeight();
	const { elementRef, offsetTop } = useHTMLElementProperties();
	const theme = useTheme();

	return (
		<StyledDiv
			id="ContainerStyledDiv"
			data-testid={id}
			isSmallFormFactor={isSmallFormFactor}
			ref={elementRef}
			clientHeight={clientHeight}
			offsetTop={offsetTop}
			offsetBottom={theme?.mastheadOffset?.daasBottomOffset || 32} // Hardcoding a value since theme is undefined in the ui-features plugin build
		>
			<ConditionalRenderer flag={isLargeFormFactor} OnComponent={StyledInnerContainer}>
				{children}
			</ConditionalRenderer>
		</StyledDiv>
	);
};
