import { getIcon } from 'Environment/launchResource/resourceLaunch';
import { Resource } from 'Workspace/ResourceProvider/resourceTypes';

/**
 * Waits for the icon image to be loaded on the canvas and returns a Promise that resolves to the data URL of the loaded image.
 * @param imageUrl - The URL of the icon image.
 * @returns A Promise that resolves to the data URL of the loaded image.
 */
function waitForIconImageLoaded(imgeUrl: string): Promise<string> {
	return new Promise(resolve => {
		const imgObj = new Image();
		imgObj.onload = function () {
			const drawingCanvas = document.createElement('canvas');
			const context = drawingCanvas.getContext('2d');
			drawingCanvas.height = imgObj.height;
			drawingCanvas.width = imgObj.width;
			context.drawImage(imgObj, 0, 0);
			const dataURL = drawingCanvas.toDataURL();
			resolve(dataURL);
		};
		imgObj.onerror = () => resolve('');
		imgObj.src = imgeUrl;
	});
}

/**
 * Retrieves the icon as data URL of the specified CVAD resource.
 * @param resource The resource for which to retrieve the app icon data URL.
 * @returns A promise that resolves to the data URL of the app icon and throws error on exception.
 */
export async function getCVADAppIconDataUrl(resource: Resource) {
	return await waitForIconImageLoaded(getIcon(resource).url);
}
