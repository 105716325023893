import { palette, PaletteName } from '@citrite/web-ui-component';
import { StoreBranding, WorkspaceConfiguration } from '@citrite/workspace-ui-platform';
import { UserBrandingFormat } from '../Workspace/UserContext/Context';

export interface BrandingStyles {
	AccentColor: string;
	HeaderBackgroundColor: string;
	HeaderTextColor: string;
}

export interface WorkspaceLinks {
	HeaderLogoUrl?: string;
	LoginLogoUrl?: string;
}

export interface AccountBranding {
	styles: BrandingStyles;
	links: WorkspaceLinks;
	paletteName: PaletteName;
}

const defaultBranding: StoreBranding = {
	backgroundColor: palette.blue100,
	linkColor: palette.blue800,
	textColor: palette.blue900,
};

export function createWorkspaceBranding(
	userBranding: Partial<UserBrandingFormat> = {},
	workspaceConfiguration: WorkspaceConfiguration
): AccountBranding {
	const userPolicyBranding = userBranding || ({} as Partial<UserBrandingFormat>);
	const workspaceBranding =
		(workspaceConfiguration &&
			workspaceConfiguration.userInterface &&
			workspaceConfiguration.userInterface.branding) ||
		{};

	return {
		paletteName: PaletteName.neutral,
		styles: {
			AccentColor:
				userPolicyBranding.linkColor ||
				workspaceBranding.linkColor ||
				defaultBranding.linkColor,
			HeaderBackgroundColor:
				userPolicyBranding.backgroundColor ||
				workspaceBranding.backgroundColor ||
				defaultBranding.backgroundColor,
			HeaderTextColor:
				userPolicyBranding.textColor ||
				workspaceBranding.textColor ||
				defaultBranding.textColor,
		},
		links: {
			HeaderLogoUrl: userPolicyBranding.headerLogo || workspaceBranding.headerLogo,
			LoginLogoUrl: userPolicyBranding.logonLogo || workspaceBranding.logonLogo,
		},
	};
}
