import * as React from 'react';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { StyledApplication } from './styled';

export const Application: React.SFC = props => {
	const isOffWhiteBackground = useFeatureCanary(FeatureFlag.EnableOffWhiteBackground);
	return (
		<StyledApplication isOffWhiteBackground={isOffWhiteBackground}>
			{props.children}
		</StyledApplication>
	);
};
